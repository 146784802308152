import React, { useEffect, useState } from "react";
import { Nav } from "../../../components/Tabs/Nav";
import Tab from "../../../components/Tabs/Tab";
import { Switch } from "../../../components/Switch/Switch";
import { tabs } from "../../../constants/casesConstants";
import CaseResolveDocuments from "./CaseResolveDocuments/CaseResolveDocuments";
import CaseResolveOverview from "./CaseResolveOverview/CaseResolveOverview";
import Claims from "./Claims/Claims";
import sparkle from "../../../Images/Sparkle.svg";
import { Image } from "../../../components/Image/Image";
import CaseResponse from "./CaseResponse/CaseResponse";
import CasesTimeLine from "./CasesTimeline/CasesTimeline";
import { ORGANIZATION_ID, AUTH_USER } from "../../../constants/enum";
import storageService from "../../../services/storageService";
import { useParams, useNavigate } from "react-router-dom";
import { CASE_ID } from "../../../constants/enum";
import Loader from "../../../components/Loaders/Loader";
import {
  CASE_UPLOAD_ROUTE,
  CASELIST_ROUTE,
} from "../../../constants/routeEnums";
import {
  getCaseInfo,
  getCaseTimelines,
  getCaseResponses,
} from "../../../services/coreService";
import { formatDateToDDMMYYYY } from "../../../utils/dateFormat";
import {
  DISPUTE_CASE_STATUS_COMPLETED,
  DISPUTE_CASE_STATUS_PROGESS,
  DISPUTE_CASE_STATUS_UPLOAD_PENDING,
  DISPUTE_CASE_STATUS_FAILED,
} from "../../../constants/disputeCaseStatusConstants";

const Cases = () => {
  const { case_id } = useParams();
  let organizationId = storageService.getItem(ORGANIZATION_ID);
  const user = storageService.getItem(AUTH_USER);

  let caseId = case_id;

  const { overview, documents, timeline, claims, response } = tabs;

  const Navigate = useNavigate();

  const [caseHighlights, setCaseHighlights] = useState([]);
  const [caseHighlightsDate, setCaseHighlightsDate] = useState();
  const [title, setTitle] = useState("");
  const [remedialData, setRemedialData] = useState([]);
  const [caseStatus, setCaseStatus] = useState("");
  const [caseData, setCaseData] = useState(null);
  const [caseTimelineData, setCaseTimelineData] = useState([]);
  const [letterData, setLetterData] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const PAGE_SIZE = 50;
  const totalPages = Math.ceil(count / PAGE_SIZE);

  const navItems = [
    { eventKey: "Overview", label: overview },
    { eventKey: "Documents", label: documents },
    { eventKey: "Timeline", label: timeline },
    { eventKey: "Claims", label: claims },
    { eventKey: "Response", label: response }
  ];

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(() => {
    const fetchCaseInfo = async () => {
      try {
        const data = await getCaseInfo(organizationId, case_id);

        setCaseData(data?.disputeCase);

        setCaseStatus(data?.disputeCase?.status);
        let editDate = formatDateToDDMMYYYY(data?.disputeCase?.updatedAt);

        setCaseHighlightsDate(editDate);
        setTitle(
          data?.disputeCase?.caseTitle
            ? data?.disputeCase?.caseTitle
            : "No Title"
        );
        setCaseHighlights(data?.disputeCase?.caseHighlights);

        let status = data?.disputeCase?.status;

        if (status === DISPUTE_CASE_STATUS_UPLOAD_PENDING) {
          storageService.setItem(CASE_ID, case_id);
          Navigate(CASE_UPLOAD_ROUTE);
        } else if (!status) {
          Navigate(CASELIST_ROUTE);
        }

        if (
          data?.disputeCase?.status === DISPUTE_CASE_STATUS_COMPLETED ||
          data?.disputeCase?.status === DISPUTE_CASE_STATUS_FAILED
        ) {
          clearInterval(interval);
        }
      } catch (error) {
        console.error("Error fetching case info:", error);
      }
    };

    fetchCaseInfo();

    const interval = setInterval(fetchCaseInfo, 5000);
    return () => clearInterval(interval);
  }, [organizationId, case_id]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition =
        window.innerHeight + document.documentElement.scrollTop;
      const bottomPosition = document.documentElement.offsetHeight;

      if (
        scrollPosition >= bottomPosition - 50 &&
        !isFetchingMore &&
        pageNum < totalPages
      ) {
        setPageNum((prevPageNum) => prevPageNum + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isFetchingMore, pageNum, totalPages]);

  useEffect(() => {
    if (caseStatus === DISPUTE_CASE_STATUS_COMPLETED || caseId) {
      fetchCaseTimelines(organizationId, caseId, pageNum);
    }
  }, [organizationId, caseId, pageNum]);

  const fetchCaseTimelines = async (organizationId, caseId, pageNum) => {
    let payload = {
      pageNum: pageNum,
      pageSize: PAGE_SIZE,
      filter: {},
    };

    try {
      if (pageNum === 1) {
        setLoading(true);
      } else {
        setIsFetchingMore(true);
      }

      const res = await getCaseTimelines(organizationId, caseId, payload);

      const { caseTimelines, totalCount } = res;
      console.log(res, "DATATATTATATATTAT");

      setCount(totalCount);

      setCaseTimelineData((prevList) => [...prevList, ...caseTimelines]);
    } catch (error) {
      console.error("Failed to fetch case list:", error);
    } finally {
      setLoading(false);
      setIsFetchingMore(false);
    }
  };

  useEffect(() => {
    if (caseStatus === DISPUTE_CASE_STATUS_COMPLETED) {
      const fetchCaseResponse = async () => {
        try {
          await delay(5);
          const pageNum = 1;
          const pageSize = 20;
          const filters = {};
          const data = await getCaseResponses(
            organizationId,
            caseId,
            pageNum,
            pageSize,
            filters
          );

          setLetterData(data?.caseResponses[0]?.letterData);
        } catch (error) {
          console.error("Error fetching Case Response:", error);
        }
      };

      fetchCaseResponse();
      // fetchCasesTimelines();
    }
  }, [caseStatus, organizationId, caseId]);

  if (caseStatus === DISPUTE_CASE_STATUS_PROGESS) {
    return (
      <div className={"mt-20"}>
        <p className={"text-center mb-5"}>
          {
            "We are working to study the case and create appropriate responses. Please wait!"
          }
        </p>
        <Loader />
      </div>
    );
  }
  if (caseStatus === DISPUTE_CASE_STATUS_FAILED) {
    return (
      <div className={"mt-20"}>
        <p className={"text-center"}>
          {"The AI Processing Failed!. Please retry again!"}
        </p>
      </div>
    );
  }



  return (
    <>
      <Tab.Container defaultActiveKey="Overview">

        {/*header*/}
        <div className="bg-white border-b">
          <div className="flex  justify-between border-b">

            <div className="flex flex-col justify-start px-4 py-3 md:px-8">
              <div className="flex gap-x-1 flex-wrap mb-2">
                <span className="text-[#000000] font-inter font-medium leading-[24px] not-italic lg:text-[22px] md:text-[20px] max-w-[500px] mb-2">
                  {title}
                </span>
                <div className="flex w-full lg:w-auto lg:flex-shrink-0">
                  <Switch
                    checked={"false"}
                    intialrender={
                      <span className="text-[12px] text-gray-500 whitespace-nowrap">
                        {"Edited on"} {"19-05-2000"}
                      </span>
                    }
                    togglerenderitem={
                      <span className="flex items-center gap-2 ml-3 text-[12px] text-blue-500 whitespace-nowrap">
                        <Image src={sparkle} />
                        {"AI Generated"}
                      </span>
                    }
                  />
                </div>
              </div>

              <div className="flex gap-4 pb-1">
                <span className="text-[#727272] font-inter text-[16px] font-medium leading-6">
                  {user?.contactName?.split(" ")[0]}
                </span>
                <Switch
                  checked={"false"}
                  intialrender={
                    <span className="text-[12px] text-gray-500  whitespace-nowrap">
                      {"Edited on"} {"19-05-2000"}{" "}
                    </span>
                  }
                  togglerenderitem={
                    <span className="flex items-center gap-2 ml-3 text-[12px] text-blue-500  whitespace-nowrap">
                      <Image src={sparkle} />
                      {"AI Generated"}{" "}
                    </span>
                  }
                />
              </div>
            </div>

            <div className="flex flex-col justify-end w-[60%] overflow-x-auto" style={{ scrollbarWidth: "none" }}>
              <Nav className="flex justify-end gap-4 px-4 text-sm md:text-base whitespace-nowrap min-w-max h-full max-h-[83px]">
                {navItems?.map((item) => (
                  <Nav.Item key={item.eventKey} eventKey={item.eventKey} className="group">
                    <div className="cursor-pointer relative px-3 flex items-center h-full">
                      <span
                        data-tab-toggle
                        data-target={item.eventKey}
                        className="text-gray-500 group-[.active]:text-black transition-all duration-300 ease-linear"
                      >
                        {item.label}
                      </span>
                      <div className="absolute left-0 bottom-0 w-full h-0.5 bg-transparent group-[.active]:bg-black transition-all duration-300 ease-linear"></div>
                    </div>
                  </Nav.Item>
                ))}
              </Nav>
            </div>

          </div>
        </div>

        {/* Content */}
        <Tab.Content className="overflow-y-auto">
          <Tab.Pane eventKey="Overview" id="Overview">
            <CaseResolveOverview caseData={caseData} />
          </Tab.Pane>
          <Tab.Pane eventKey="Documents" id="Documents">
            <CaseResolveDocuments
              caseId={caseId}
              organizationId={organizationId}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="Timeline" id="Timeline">
            <CasesTimeLine
              caseTimelineData={caseTimelineData}
              isFetchingMore={isFetchingMore}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="Claims" id="Claims">
            <Claims
              caseId={caseId}
              organizationId={organizationId}
              setRemedialData={setRemedialData}
              caseStatus={caseStatus}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="Response" id="Response">
            <CaseResponse
              caseHighlights={caseHighlights}
              remedialData={remedialData}
              caseHighlightsDate={caseHighlightsDate}
              letterData={letterData}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default Cases;
