import React, { useState } from "react";

const Image = ({
  src,
  alt = "Icon",
  className = "",
  loadingClass = "bg-gray-200 animate-pulse",
  errorClass = "bg-red-100 text-red-500 text-center flex items-center justify-center text-[14px]",
  fallback = "Image failed to load",
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const handleLoad = () => setIsLoading(false);
  const handleError = () => {
    setIsLoading(false);
    setHasError(true);
  };

  return (
    <>
      {isLoading && !hasError && (
        <div className={`image-placeholder ${loadingClass}`} />
      )}
      {!isLoading && hasError && (
        <div className={` ${errorClass}`} s>
          {fallback}
        </div>
      )}
      {!hasError && (
        <img
          src={src}
          alt={alt}
          className={`${className} ${isLoading ? "hidden" : ""}`}
          onLoad={handleLoad}
          onError={handleError}
          {...props}
        />
      )}
    </>
  );
};

export { Image };
