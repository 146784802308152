import React from "react";
import InputForm from "../../../../../components/InputForm/InputForm";
import Button from "../../../../../components/Button/Button";
import collapse from "../../../../../Images/Collapse.svg";
import { memberRole } from "../../../../../constants/casesConstants";
import SelectDropdown from "../../../../../components/DropDown/Dropdown";
import eye from '../../../../../Images/eye.svg'
import closeeye from '../../../../../Images/eyeclose.svg'

function AddUpdateMember({
  role,
  member,
  setRole,
  isEditMode,
  handleChange,
  showPassword ,
  handleShowPassword,
  handleAddMember,
  handleUpdateMember,
  handleAddUpdateModalClose,
}) {
  return (
    <div>



      <div>
      <div className="py-[5px]">
        <InputForm
          value={member.firstName || ""}
          handleChange={(e) => handleChange("firstName", e.target.value)}
          label="First Name"
          placeholder="Enter first name"
          isValid={true}
        />
      </div>
      <div className="py-[5px]">
        <InputForm
          value={member.lastName}
          handleChange={(e) => handleChange("lastName", e.target.value)}
          label="Last Name"
          placeholder={"Enter lastName"}
          isValid={true}
        />
      </div>
      <div className="py-[5px]">
        <InputForm
          value={member.contactName}
          handleChange={(e) => handleChange("contactName", e.target.value)}
          label="Contact Name"
          placeholder={"Enter Contact Name"}
          isValid={true}
        />
      </div>
      {!isEditMode &&
      <div className="py-[5px]">
        <InputForm
          value={member.username}
          handleChange={(e) => handleChange("username", e.target.value)}
          label="Username"
          placeholder={"Enter Username"}
          isValid={true}
        />
      </div>
}
      <div className="py-[5px]">
        <InputForm
          value={member.contactEmail}
          handleChange={(e) => handleChange("contactEmail", e.target.value)}
          label="Conatct Email"
          placeholder={"Enter Email"}
          isValid={true}
        />
      </div>
      <div className="py-[5px]">
        <InputForm
          value={member.contactPhone}
          handleChange={(e) => handleChange("contactPhone", e.target.value)}
          label="Contact Phone"
          placeholder={"Enter Phone Number"}
          isValid={true}
        />
      </div>
      {!isEditMode &&
      <> 
      <div className="py-[5px]"> 
        <InputForm
          value={member.employeeId}
          handleChange={(e) => handleChange("employeeId", e.target.value)}
          label="Employee Id"
          placeholder={"Enter Employee Id"}
          isValid={true}
        />
      </div>
      <div className="py-[5px]">
  <div style={{ position: 'relative' }}>
    <InputForm
      value={member.password}
      handleChange={(e) => handleChange("password", e.target.value)}
      label="Password"
      placeholder="Enter Password"
      isValid={true}
      type={showPassword ? "text" : "password"}
    />
    <div
      style={{
        position: 'absolute',
        right: '40px',
        marginTop:'13px',
        top: '50%',
        transform: 'translateY(-50%)',
        cursor: 'pointer'
      }}
      onClick={() => handleShowPassword()}
    >
      <img src={showPassword ? eye : closeeye} alt="toggle visibility" />
    </div>
  </div>
</div>

      </>
}
      <div className="px-8 py-[5px]">
        <p 
          className="block mb-2 text-sm font-medium text-gray-700"
        >Role</p>
        <SelectDropdown
          options={memberRole}
          value={role}
          onChange={(val) => setRole(val)}
          collapseIcon={collapse}
          placeholder="Select an option..."
        />
      </div>
      </div>
    </div>
  );
}

export default AddUpdateMember;
