import { useState } from "react";
import SideBar from "../components/SideBar/SideBar";
import Header from "../components/Header/Header";
import { Divider } from "../components/Divider/Divider";

const Layouts = ({ children }) => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  return (
    <div className="flex w-full">
      {/* Sidebar */}
      <div
        className={`min-h-screen bg-gray-100 border-r border-gray-300 transition-all duration-300 ${sidebarCollapsed ? "w-[80px]" : "w-[220px]"
          }`}
      >
        <SideBar setCollapsed={setSidebarCollapsed} />
      </div>

      {/* Main Content */}
      <div className="flex-1 w-[70%] flex flex-col">
        {/* Header */}
        <div>
          <Header />
        </div>
        <Divider />

        {/* Content Area */}
        <div className="flex-1 overflow-y-auto">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layouts;
