import React from "react";

const Divider = ({
  orientation = "horizontal",
  className = "",
  color = "border-[#E4E4E4]",
  thickness = "border",
  length = "w-full",
}) => {
  return (
    <div
      className={`${
        orientation === "horizontal" ? `h-0 ${length}` : `w-0 ${length}`
      } 
      ${color} ${thickness} ${
        orientation === "horizontal" ? "border-t" : "border-l"
      } ${className}`}
    />
  );
};

export { Divider };
