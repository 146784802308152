
export const DISPUTE_CASE_STATUS_UPLOAD_PENDING = 'document_upload_pending'; // INITIAL

export const DISPUTE_CASE_STATUS_SENT_FOR_PROCESSING = 'sent_to_ai'; // INTERMEDIATE

export const DISPUTE_CASE_STATUS_PROGESS = 'processing_ai'; // INTERMEDIATE

export const DISPUTE_CASE_STATUS_COMPLETED = 'processing_ai_complete'; // FINAL

export const DISPUTE_CASE_STATUS_FAILED = 'processing_ai_fail'; // FINAL

