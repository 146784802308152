import { Link } from "react-router-dom";

const Rules = () => {
  const defaultcss =
    "font-inter  font-normal leading-[150%] text-[#4976F4] underline decoration-solid decoration-auto decoration-[#4976F4] text-underline-offset-auto";
  return (
    <>
      <div className="mx-10 my-10 text-[20px]">
        If <Link className={defaultcss}>Complaint</Link> mentions{" "}
        <Link className={defaultcss}>Netflix Bundling</Link> then{" "}
        <Link className={defaultcss}>Email Legal</Link> and{" "}
        <Link className={defaultcss}>Add remediation</Link>.
      </div>
    </>
  );
};

export default Rules;
