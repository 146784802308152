import React from "react";
import Timeline from "../../../../components/Timeline/Timeline";
import Switch2_0 from "../../../../components/SwitchV2/SwitchV2";
import Loader from "../../../../components/Loaders/Loader";

const CasesTimeLine = ({ caseTimelineData, isFetchingMore }) => {

  return (
    <div className="px-[32px] pt-[16px]" style={{ maxHeight: "calc(100vh - 88px)", overflowY: "auto" }}>
      {(!caseTimelineData || caseTimelineData?.length === 0) ? (
        <div className="flex items-center justify-center h-[50vh] text-center text-gray-500">
          <p>No timeline data available at the moment.</p>
        </div>
      )
        : (
          <div>
            <div data-testid="timeline" className="flex items-center gap-2">
              <Switch2_0 initialState="false" />
            </div>
            <Timeline events={caseTimelineData} />
          </div>
        )}
      {isFetchingMore && (
        <div data-testid="loader" className="flex justify-center mt-4 mb-6">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default CasesTimeLine;
