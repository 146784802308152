import { Divider } from "../../../../components/Divider/Divider";
import megaphone from "../../../../Images/Megaphone.svg";
import rightarrow from "../../../../Images/RightArrow.svg";
import { useEffect, useState } from "react";
import Overlay from "../../../../components/Overlay/Overlay";
import Button from "../../../../components/Button/Button";
import InputForm from "../../../../components/InputForm/InputForm";
import TextAreaForm from "../../../../components/FormTextArea/FormTextArea";
import ClaimResponse from "./ClaimResponse";
import callApi from "../../../../services/apiService";
import {
  confirmRemedialActionByAgent,
  createRemedialAction,
  createsubclaim,
  fetchCaseClaimValidate,
  fetchCaseResponse,
  fetchClaimList,
  fetchMonetaryvalidate,
  fetchremedialactions,
  updateRemedialAction,
  updatesubclaiminfo,
} from "../../../../services/coreService";
import { AGENT_ID, CASE_ID, ORGANIZATION_ID } from "../../../../constants/enum";
import {
  showNotification,
} from "../../../../components/Toastify/Toast";
import { validityoptions } from "../../../../constants/casesConstants";
import SelectDropdown from "../../../../components/DropDown/Dropdown";
import collapse from "../../../../Images/Collapse.svg";
import storageService from "../../../../services/storageService";
import Loader from "../../../../components/Loaders/Loader";
import SubClaims from "./SubClaims"

const Claims = ({ caseId, organizationId, setRemedialData, caseStatus }) => {
  const [isremedialmodal, setRemedialModal] = useState(false);
  const [iseditvaliditymodal, setEditValidityModal] = useState(false);
  const [issubclaimmodal, setSubClaimModal] = useState(false);
  const [iseditmonetarymodal, setEditMonetaryModal] = useState(false);
  const [isescalatemodal, setEscalateModal] = useState(false);
  const [isshowclaim, setIsShowClaim] = useState(true);
  const [editclaim, seteditclaim] = useState(true);
  const [caseid, setCaseId] = useState({});
  const [claimlist, setClaimList] = useState([]);
  const [caseresponse, setCaseResponse] = useState([]);
  const [remedialtitle, setRemedialTitle] = useState("");
  const [remedialjustification, setRemedialJustification] = useState("");
  const [editremedialaction, setEditRemedialAction] = useState([]);
  const [selectvalidity, setSelectedvalidity] = useState("");
  const [monetarytitle, setMonetaryTitle] = useState("");
  const [monetaryeditreason, setMonetaryEditReason] = useState("");
  const [subclaimtitle, setSubClaimTitle] = useState("");
  const [editClaimDetails, setEditClaimDetails] = useState({});
  const [editmonetaryDetails, setEditMonetaryDetails] = useState({});
  const [remedialactionlist, setRemedialActionList] = useState([]);
  const [remedialactionDetails, setRemedialActionDetails] = useState({});
  const [caseValidateState, setCaseValidateState] = useState({});
  const [monetaryValueState, setMonetaryValueState] = useState({});
  const [remedialValueState, setRemediaValueState] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(null)
  const [selectedClaim, setSelectedClaim] = useState(
    claimlist?.map((_, index) => index) || []
  );
  const [remedialclaimid, setremedialclaimid] = useState(null)
  const [claimcaseid, setClaimCaseId] = useState(null)
  const [pageNum, setPageNum] = useState(1);
  const [count, setCount] = useState(0)
  const [isFetchingMore, setIsFetchingMore] = useState(false);


const PAGE_SIZE= 50;
  const agentId = storageService.getItem(AGENT_ID);
  const totalPages = Math.ceil(count / PAGE_SIZE);

  useEffect(() => {
    fetchClaims();
    if(caseId) {
    fetchRemedialActions(caseId)
    }
    // fetchResponse();
  }, [organizationId, caseId, caseStatus, pageNum]);

  useEffect(() => {
    if (claimlist?.length > 0) {
      setSelectedClaim(claimlist.map((_, index) => index));
    }
  }, [claimlist]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.innerHeight + document.documentElement.scrollTop;
      const bottomPosition = document.documentElement.offsetHeight;
  
      if (scrollPosition >= bottomPosition - 50 && !isFetchingMore && claimlist.length < count) {
        loadMoreCaseClaim();
      }
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll); 
  }, [isFetchingMore, claimlist.length, count]);



  const handlRemedialModalOpen = (action) => {
    setRemedialActionDetails(action);
    setRemedialTitle(action?.title || "");
    setRemedialJustification(action?.description || "");
    setRemedialModal(true);
  };

  const handlcreateRemedialModalOpen = (id) => {
    setremedialclaimid(id)
    setRemedialActionDetails(null);
    setRemedialModal(true);
  };

  const handlRemedialModalClose = () => {
    setRemedialActionDetails({});
    setRemedialModal(false);
  };

  const handleSubClaimModalOpen = (claim) => {
    setEditClaimDetails(claim);
    setSubClaimTitle(claim?.title || "");
    setSubClaimModal(true);
  };

  const handlSubClaimModalClose = () => {
    setEditClaimDetails({});
    setSubClaimModal(false);
  };

  const handlEditMonetaryModalOpen = (claim) => {
    setEditMonetaryDetails(claim);
    setMonetaryTitle(claim?.editMonetaryValue || "");
    setMonetaryEditReason(claim?.editMonetaryValueReason || "");
    setEditMonetaryModal(true);
  };

  const handlEditMonetaryModalClose = () => {
    setEditMonetaryDetails({});
    setEditMonetaryModal(false);
  };

  const handlEditValidityModalOpen = (claim) => {
    setEditMonetaryDetails(claim);
    setEditValidityModal(true);
  };

  const handlEditValidityModalClose = () => {
    setEditValidityModal(false);
  };

  const handleshowClaim = (index) => {
    if (selectedClaim.some((item) => item === index)) {
      setSelectedClaim(selectedClaim.filter((item) => item !== index));
    } else {
      let array = [...selectedClaim];
      array.push(index);
      setSelectedClaim(array);
    }
  };


  const decisionColor = (val) => {
    if (val === "make_offer") {
      return "#A8DEB4";
    } else if (val === "submit_defence") {
      return "#AF4B4B";
    }
  };

  const handleRemedialValidation = (action) => {
    let isValid = true;
    if (!!!action.title || action.title === "") {
      showNotification("Please Enter Title", "error");
      isValid = false;
    } else if (!!!action.description || action.description === "") {
      showNotification("Please Enter Justification", "error");
      isValid = false;
    }

    return isValid;
  };

  const handleEditMonetaryValueValidation = (action) => {
    let isValid = true;
    if (!!!action.monetaryValue || action.monetaryValue === "") {
      showNotification("Please Enter Value", "error");
      isValid = false;
    } else if (!!!action.editReason || action.editReason === "") {
      showNotification("Please Enter Justification", "error");
      isValid = false;
    }

    return isValid;
  };

  const handleSubClaimValidation = (action) => {
    let isValid = true;
    if (!!!action.subclaimtitle || action.subclaimtitle === "") {
      showNotification("Please Enter Title", "error");
      isValid = false;
    }

    return isValid;
  };

  const handleCaseClaimValidate = async (claimId) => {
    let newValidate = { action: "toggle_validity_confirmation" };
    let response = await fetchValidate(claimId, newValidate);
    if (response?.status === "Success") {
      let newList = claimlist.map(item => {
        if (item._id === response.data.caseClaim._id) {
          return response.data.caseClaim;
        }
        return item;
      })
      setClaimList(newList);
      const { message } = response.data;
      showNotification(message, "success");
    }
  };

  const handleMonetaryConformation = async (claim, claimId, isChecked) => {
    let response;
    if (isChecked) {
      let newValidate = { action: "toggle_money_confirmation" };
      response = await fetchMonetaryValidate(claimId, newValidate);
    } else {
      let newValidate = { action: "validate" };
      response = await fetchMonetaryValidate(claimId, newValidate);
    }
    if (response.status === "Success") {
      let newList = claimlist.map(item => {
        if (item._id === response.data.caseClaim._id) {
          return response.data.caseClaim;
        }
        return item;
      })
      setClaimList(newList);
    }
  };

  const handleRemedialConformation = async (claim, claimId) => {
    let response = await confirmRemedialActionByAgent(organizationId, claimId);

    if (response.status === "Success") {
      let newList = claimlist.map(item => {
        if (item._id === response.data.caseClaim._id) {
          return response.data.caseClaim;
        }
        return item;
      })
      setClaimList(newList);
    }
  };

  const fetchClaims = async () => {
    const payload = {
      pageNum: pageNum,
      pageSize: PAGE_SIZE,
      filters: {},
    };
  
    try {
      setIsFetchingMore(true); 
  
      const res = await fetchClaimList(organizationId, caseId, payload);
  
      if (res?.status === "Success") {
        const { caseClaims, totalCount } = res.data;
        setCount(totalCount); 
  
        setClaimList((prevList) => [...prevList, ...caseClaims]);
      }
    } catch (error) {
      console.error("Failed to fetch case list:", error);
    } finally {
      setIsFetchingMore(false); 
    }
  };

  const loadMoreCaseClaim = () => {
    if (claimlist.length < count) { 
      setPageNum(prevPageNum => prevPageNum + 1); 
    }
  };

  const fetchRemedialActions = async (caseId) => {
    try {
      const res = await fetchremedialactions(organizationId, caseId);
      if (res?.status === "Success") {
        const { remedialAction } = res.data;
        setRemedialData(remedialAction);
        const groupedActions = remedialAction.reduce((acc, action) => {
          const caseClaimId = action.caseClaimId;
          if (!acc[caseClaimId]) {
            acc[caseClaimId] = [];
          }
          acc[caseClaimId].push(action);
          return acc;
        }, {});

        setRemedialActionList((prev) => ({
          ...prev,
          ...groupedActions,
        }));
        setClaimCaseId(caseId);
      }
    } catch (error) {
      showNotification("error", "error");
    }
  };


  const handleClaimValidate = async (claimId, validate) => {

    const payload = {
      action: validate,
    };
    const res = await fetchCaseClaimValidate(
      organizationId,
      claimId,
      payload
    );

    if (res?.status === "Success") {
      let newList = claimlist.map(item => {
        if (item._id === res.data.caseClaim._id) {
          return res.data.caseClaim;
        }
        return item;
      })
      setClaimList(newList);
      const { message } = res.data;
      handlEditValidityModalClose();
      showNotification(message, "success");
    } else {
      const errorMessage = res?.data?.message || "action key can not be empty";
      showNotification(errorMessage, "error");
    }
  };

  const fetchMonetaryValidate = async (claimId, validate) => {
    const payload = validate;
    const res = await fetchMonetaryvalidate(organizationId, claimId, payload);
    return res;
  };

  const fetchValidate = async (claimId, validateState) => {
    const payload = {
      ...validateState
    };
    const res = await fetchCaseClaimValidate(organizationId, claimId, payload);
    console.log(res);
    return res;
  };


  const createRemedialaction = async (id) => {
    try {
      const payload = {
        assignedAgent: agentId,
        caseId: claimcaseid,
        title: remedialtitle,
        description: remedialjustification,
        createdBy: agentId,
        isEdited: false,
        editTitle: "",
        editDescription: ""
      };

      if (handleRemedialValidation(payload)) {
        let res;

        if (remedialactionDetails) {
          res = await updateRemedialAction(organizationId, id, payload);
        } else {
          res = await createRemedialAction(organizationId, id, payload);
        }

        if (res?.status === "Success") {
          const { remedialAction } = res.data;
          setEditRemedialAction(remedialAction);
          fetchRemedialActions(claimcaseid);
          handlRemedialModalClose();
          setRemedialTitle("");
          setRemedialJustification("");
        }
      }
    } catch (error) {
      showNotification("An error occurred in remedial action", "error");
    }
  };

  const editMonetaryValue = async (claimId) => {
    try {
      const payload = {
        action: "invalidate",
        monetaryValue: monetarytitle,
        editReason: monetaryeditreason,
      };

      if (handleEditMonetaryValueValidation(payload)) {
        const res = await fetchMonetaryvalidate(
          organizationId,
          claimId,
          payload
        );

        if (res?.status === "Success") {
          handlEditMonetaryModalClose();
          setMonetaryTitle("");
          setMonetaryEditReason("");
          fetchClaims();
        } else {
          showNotification("Failed to edit monetary value");
        }
      }
    } catch (error) {
      showNotification("An error occurred while editing monetary value", "error");
    }
  };

  const handleSubClaim = async (claimId) => {
    try {
      const action = { subclaimtitle };
      const isValid = handleSubClaimValidation(action);

      if (!isValid) {
        return;
      }
      const payload = {
        caseClaim: {
          caseId: caseId,
          title: subclaimtitle,
        },
      };

      let res;
      if (editClaimDetails) {
        res = await updatesubclaiminfo(organizationId, claimId, payload);
      } else {
        res = await createsubclaim(organizationId, agentId, payload);
      }
      if (res?.status === "Success") {
        handlSubClaimModalClose();
        setSubClaimTitle("");
        fetchClaims();
      } else {
        showNotification(
          `Failed to ${editClaimDetails ? "edit" : "create"} sub claims`,
          "error"
        );
      }
    } catch (error) {
      showNotification("An error occurred in sub claims", "error");
    }
  };


  return (
    <div className="px-[26px] pt-[16px]">
      <div >
        <span className="text-[22px] ml-4 text-black">Subclaims</span>
        {claimlist?.map((claim, index) => (
          <div data-testid="claimlist">
          <SubClaims
            key={index}
            index={index}
            handleshowClaim={() => handleshowClaim(index)}
            handlRemedialModalOpen={handlRemedialModalOpen}
            selectedClaim={selectedClaim}
            claim={claim}
            remedialactionlist={remedialactionlist[claim?._id] || []}
            handleCaseClaimValidate={handleCaseClaimValidate}
            handleMonetaryConformation={handleMonetaryConformation}
            handleRemedialConformation={handleRemedialConformation}
            handlEditMonetaryModalOpen={handlEditMonetaryModalOpen}
            handlEditValidityModalOpen={handlEditValidityModalOpen}
            handleSubClaimModalOpen={handleSubClaimModalOpen}
            caseValidateState={caseValidateState}
            monetaryValueState={monetaryValueState}
            selectedIndex={selectedIndex}
            handlcreateRemedialModalOpen={handlcreateRemedialModalOpen}
            fetchRemedialActions={fetchRemedialActions}
            setCaseId={setCaseId}
          />
          </div>
        ))}
      </div>
      <div className="py-6" onClick={() => handleSubClaimModalOpen()}>
        <span className="text-[18px] ml-4 font-normal cursor-pointer">+ Add a Subclaim</span>
      </div>
      <Divider />

      {isFetchingMore && (
              <div className="flex justify-center py-4">
                <Loader />
              </div>
            )}

      {/* <div className="mt-6">
        <ClaimResponse DecisionColor={decisionColor} />
      </div> */}

      {/* <div className="flex flex-row justify-end gap-3 mt-8">
        <Button
          icon={megaphone}
          className="bg-[#AF4B4B] px-4 py-1 "
          text={"Escalate"}
        />
        <Button
          icon={rightarrow}
          className="bg-[#A8DEB4] px-4 py-1 "
          text={"Generate Response"}
        />
      </div> */}

      <Overlay
        isOpen={isremedialmodal}
        onClose={handlRemedialModalClose}
        title={
          remedialactionDetails
            ? "Edit Remedial Action"
            : "Create Remedial Action"
        }
        className="custom-class"
        position="center"
        data-testid="remedialModal"
      >
        <div >
          <InputForm
            label="Title"
            value={remedialtitle}
            handleChange={(e) => setRemedialTitle(e.target.value)}
            placeholder={"e.g. Wipe customers credit account"}
            isValid={true}
          />
        </div>
        <div>
          <TextAreaForm
            label="Justification"
            value={remedialjustification}
            handleChange={(e) => setRemedialJustification(e.target.value)}
            placeholder={
              "e.g. Based on the evidence provided it is clear that a mistake was made regarding the customers credit status and their credit account should be wiped"
            }
            isValid={true}
          />
        </div>
        <div className="flex flex-row justify-between gap-6 px-8 mt-8">
          {/* {remedialactionDetails && (
            <Button
              className="w-full bg-[#ffffff] border border-red-600 px-4 py-2"
              text={"Delete"}
              textcolor="red"
              onClick={handlRemedialModalClose}
            />
          )} */}
          <Button
            onClick={handlRemedialModalClose}
            className="w-full bg-[#F2F2F2] px-4 py-2 "
            text={"Cancel"}
            textcolor="#000000"
            data-testid="closeRemedialButton" 
          />
          <Button
            className="w-full bg-[#000000] text-black px-4 py-2 "
            text={remedialactionDetails ? "Edit" : "Create"}
            onClick={() => createRemedialaction(remedialactionDetails ? remedialactionDetails?._id : remedialclaimid)}
          />
        </div>
      </Overlay>
      <Overlay
        isOpen={iseditmonetarymodal}
        onClose={handlEditMonetaryModalClose}
        title="Edit Monetary Value"
        className="custom-class"
        position="center"
      >
        <div>
          <InputForm
            value={monetarytitle}
            handleChange={(e) => setMonetaryTitle(e.target.value)}
            label="Value"
            placeholder={"e.g. Wipe customers credit account"}
            isValid={true}
          />
        </div>
        <div>
          <TextAreaForm
            value={monetaryeditreason}
            handleChange={(e) => setMonetaryEditReason(e.target.value)}
            label="Justification"
            placeholder={
              "e.g. Based on the evidence provided it is clear that a mistake was made regarding the customers credit status and their credit account should be wiped"
            }
            isValid={true}
            data-testid="editmonetary"
          />
        </div>
        <div className="flex flex-row justify-between gap-6 px-8 mt-8">
          {/* {editclaim && (
            <Button
              className="w-full bg-[#ffffff] border border-red-600 px-4 py-2"
              text={"Cancel"}
              textcolor="red"
              onClick={handlEditMonetaryModalClose}
            />
          )} */}
          <Button
            className="w-full bg-[#F2F2F2] px-4 py-2 "
            text={"Cancel"}
            textcolor="#000000"
            onClick={handlEditMonetaryModalClose}
          />
          <Button
            className="w-full bg-[#000000] text-black px-4 py-2 "
            text={"Edit"}
            onClick={() => editMonetaryValue(editmonetaryDetails?._id)}
          />
        </div>
      </Overlay>
      <Overlay
        isOpen={iseditvaliditymodal}
        onClose={handlEditValidityModalClose}
        title="Edit Validity"
        className="custom-class"
        position="center"
      >
        <div className="px-8">
          <SelectDropdown
            options={validityoptions}
            value={selectvalidity}
            onChange={(val) => setSelectedvalidity(val)}
            collapseIcon={collapse}
            placeholder="Select an option..."
          />
        </div>
        <div className="flex flex-row justify-between gap-6 px-8 mt-8">
          {/* {editclaim && (
            <Button
              className="w-full bg-[#ffffff] border border-red-600 px-4 py-2"
              text={"Delete"}
              textcolor="red"
              onClick={handlEditValidityModalClose}
            />
          )} */}
          <Button
            className="w-full bg-[#F2F2F2] px-4 py-2 "
            text={"Cancel"}
            textcolor="#000000"
            onClick={handlEditValidityModalClose}
          />
          <Button
            className="w-full bg-[#000000] text-black px-4 py-2 "
            text={"Edit"}
            onClick={() => handleClaimValidate(editmonetaryDetails?._id, selectvalidity)}
          />
        </div>
      </Overlay>
      <Overlay
        isOpen={issubclaimmodal}
        onClose={handlSubClaimModalClose}
        title={editClaimDetails ? "Edit Subclaim" : "Create Subclaim"}
        className="custom-class"
        position="center"
      >
        <div>
          <InputForm
            label="Title"
            value={subclaimtitle}
            handleChange={(e) => setSubClaimTitle(e.target.value)}
            placeholder={"e.g. Wipe customers credit account"}
            isValid={true}
            data-testid="details"
          />
        </div>
        <div className="flex flex-row justify-between gap-6 px-8 mt-8">
          {/* {editClaimDetails && (
            <Button
              className="w-full bg-[#ffffff] border border-red-600 px-4 py-2"
              text={"Delete"}
              textcolor="red"
              onClick={handlSubClaimModalClose}
            />
          )} */}
          <Button
            className="w-full bg-[#F2F2F2] px-4 py-2 "
            text={"Cancel"}
            textcolor="#000000"
            onClick={handlSubClaimModalClose}
          />
          <Button
            className="w-full bg-[#000000] text-black px-4 py-2 "
            text={editClaimDetails ? "Edit" : "Create"}
            onClick={() => handleSubClaim(editClaimDetails?._id)}
            data-testid="Submit"
          />
        </div>
      </Overlay>
      {/*
 
      <Overlay
        isOpen={isremedialmodal}
        onClose={handlRemedialModalClose}
        title="Edit Validity"
        className="custom-class"
        position="center"
      >
        <div className="px-8">
          <SelectDropdown
            options={EscalateCase}
            value={selectvalidity}
            onChange={setSelectedvalidity}
            collapseIcon={collapse}
            placeholder="Select an option..."
          />
        </div>
        <div>
          <TextAreaForm
            label="Justification"
            placeholder={
              "e.g. Based on the evidence provided it is clear that a mistake was made regarding the customers credit status and their credit account should be wiped"
            }
            isValid={true}
          />
        </div>
        <div className="flex flex-row justify-between gap-6 px-8 mt-8">
          <Button
            className="w-full bg-[#F2F2F2] px-4 py-2 "
            text={"Cancel"}
            textcolor="#000000"
          />
          <Button
            icon={megaphone}
            className="w-full bg-[#AF4B4B] px-4 py-1 "
            text={"Escalate"}
          />
        </div>
      </Overlay> */}
    </div>
  );
};

export default Claims;


























































