import React from "react";
import Edit from "../../Images/Edit.svg";
import { Image } from "../Image/Image";

function EditButton({ onClick }) {
  return (
    <div>
      <button
        className="flex items-center px-3 py-1 text-[11px] text-black font-medium border border-slate-950 rounded-[4px] hover:bg-gray-100"
        onClick={onClick}
        aria-label="Edit"
      >
        <Image
          className="w-[12px] h-[12px] mr-2"
          src={Edit}
          alt="Edit Icon"
        />
        Edit
      </button>
    </div>
  );
}

export default EditButton;
