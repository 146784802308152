import callApi from "./apiService";
import { showNotification } from "../components/Toastify/Toast";
import storageService from "./storageService";
import {
  AGENT_ID,
  AUTH_USER,
  CASE_ID,
  ORGANIZATION_ID,
  TOKEN,
} from "../constants/enum";
import { INVALID_EMAIL, INVALID_PASSWORD } from "../constants/authConstants";

export const attemptLogin = async (creds) => {
  let res;
  try {
    res = await callApi({
      endpoint: `v1/authentication/login`,
      method: "POST",
      body: {
        ...creds,
      },
    });

    if (res?.data?.status === "Success") {
      showNotification(res?.data?.data?.message, "success");

      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    let msg;
    if (e?.response?.data?.data?.message === "Invalid emailId") {
      msg = INVALID_EMAIL;
    }
    else if (e?.response?.data?.data?.message === "Invalid password") {
      msg = INVALID_PASSWORD;
    }
    else {
      msg = e?.response?.data?.data?.message;
    }
    showNotification(msg, "error");
  }
};

export const attemptForgotPasword = async (email) => {
  let res;

  try {
    res = await callApi({
      endpoint: `v1/authentication/forget-password`,
      method: "POST",
      body: email,
    });

    if (res?.data?.status === "Success") {
      showNotification(res?.data?.data?.message, "success");

      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.data?.message, "error");
  }
};

export const attemptResetPassword = async (creds) => {
  let res;
  try {
    res = await callApi({
      endpoint: `v1/authentication/reset-password`,
      method: "POST",
      body: {
        ...creds,
      },
    });

    if (res?.data?.status === "Success") {
      showNotification(res?.data?.data?.message, "success");

      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.data?.message, "error");
  }
};

export const fetchAgentInfo = async (organizationId, agentId) => {
  try {
    const res = await callApi({
      endpoint: `v1/profile/organization/${organizationId}/agent/${agentId}/info`,
      method: "GET",
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "success");
    }
  } catch (e) {
    showNotification(
      e.message || "An error occurred while fetching agent info"
    );
  }
};

export const fetchCaseList = async (organizationId, agentId, payload) => {
  try {
    const res = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/agent/${agentId}/case-list`,
      method: "POST",
      body: {
        ...payload
      }
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "success");
    }
  } catch (e) {
    showNotification(
      e.message || "An error occurred while fetching agent info"
    );
  }
};

export const getCaseInfo = async (organizationId, caseId) => {
  try {
    let res = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/case/${caseId}/info`,
      method: "GET",
    });
    if (res?.data?.status === "Success") {
      return res?.data?.data;
    } else {
      showNotification(
        res?.data?.data?.message || "Failed to fetch case info",
        "error"
      );
    }
  } catch (e) {
    console.log(e);
    showNotification(
      e?.response?.data?.message || "An error occurred",
      "error"
    );
  }
};

export const getCaseDocuments = async (
  organizationId,
  caseId,
  payload
) => {
  try {
    const response = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/case/${caseId}/case-documents`,
      method: "POST",
      body: {
        ...payload
      },
    });

    if (response?.data?.status === "Success") {
      return response?.data?.data;
    } else {
      showNotification(
        response?.data?.message || "Failed to fetch case documents",
        "error"
      );
    }
  } catch (error) {
    showNotification(
      error?.response?.data?.message ||
      "An error occurred while fetching case documents",
      "error"
    );
    throw error;
  }
};

export const createSignedRequest = async (documentUrl,organizationId) => {
  try {
    const response = await callApi({
      endpoint: `v1/storage/create-signed-request?organizationId=${organizationId}`,
      method: "POST",
      body: {
        document_url: documentUrl,
      },
    });
    if (response?.data?.status === "Success") {
      return response.data.data;
    } else {
      showNotification(
        response?.data?.message || "Failed to create signed request",
        "error"
      );
    }
  } catch (error) {
    showNotification(
      error?.response?.data?.message ||
      "An error occurred while creating signed request",
      "error"
    );
    throw error;
  }
};

export const getCaseTimelines = async (
  organizationId,
  caseId,
  payload
) => {
  try {
    const response = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/case/${caseId}/case-timelines`,
      method: "POST",
      body: {
        ...payload
      },
    });

    if (response?.data?.status === "Success") {
      return response?.data?.data;
    } else {
      showNotification(
        response?.data?.message || "Failed to fetch case timelines",
        "error"
      );
    }
  } catch (error) {
    showNotification(
      error?.response?.data?.message ||
      "An error occurred while fetching case timelines",
      "error"
    );
    throw error;
  }
};

export const getCaseResponses = async (
  organizationId,
  caseId,
  pageNum,
  pageSize,
  filters = {}
) => {
  try {
    const response = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/case/${caseId}/case-responses`,
      method: "POST",

      body: {
        pageNum,
        pageSize,
        filters,
      },
    });

    if (response?.data?.status === "Success") {
      return response?.data?.data;
    } else {
      showNotification(
        response?.data?.message || "Failed to fetch case responses",
        "error"
      );
    }
  } catch (error) {
    showNotification(
      error?.response?.data?.message ||
      "An error occurred while fetching case responses",
      "error"
    );
    throw error;
  }
};

export const attemptCreateCase = async (
  organizationId,
  agentId,
  disputeCase
) => {
  try {
    const res = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/agent/${agentId}/new-case`,
      method: "POST",
      body: {
        disputeCase: disputeCase,
      },
    });

    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "success");
    }
  } catch (e) {
    showNotification(
      e.message || "An error occurred while fetching agent info"
    );
  }
};

export const fetchClaimList = async (organizationId, caseId, payload) => {
  try {
    const res = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/case/${caseId}/case-claims`,
      method: "POST",
      body: { ...payload },
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const fetchCaseResponse = async (organizationId, caseId) => {
  try {
    const res = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/case/${caseId}/case-responses`,
      method: "POST",
      body: {
        pageNum: 1,
        pageSize: 20,
        filters: {},
      },
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const fetchCaseClaimValidate = async (
  organizationId,
  claimId,
  payload
) => {
  try {
    const res = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/case-claim/${claimId}/validate-claim`,
      method: "POST",
      body: {
        ...payload,
      },
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const fetchMonetaryvalidate = async (
  organizationId,
  claimId,
  payload
) => {
  try {
    const res = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/case-claim/${claimId}/validate-monetary-value`,
      method: "POST",
      body: { ...payload },
    });
    if (res.data.status === "Success") {
      showNotification(res?.data?.data?.message, "success");

      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const confirmRemedialActionByAgent = async (
  organizationId,
  claimId
) => {
  try {
    const res = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/case-claim/${claimId}/confirm-remedial-actions`,
      method: "POST",
      body: {},
    });
    if (res.data.status === "Success") {
      showNotification(res?.data?.data?.message, "success");

      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const createRemedialAction = async (
  organizationId,
  claimId,
  payload
) => {
  try {
    const res = await callApi({
      endpoint: `v1/remedial-action/organization/${organizationId}/claim/${claimId}/create-remedial-action`,
      method: "POST",
      body: {
        remedialAction: payload,
      },
    });
    if (res.data.status === "Success") {
      showNotification("Create Succesfully", "success");
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const updateRemedialAction = async (
  organizationId,
  remedialId,
  payload
) => {
  try {
    const res = await callApi({
      endpoint: `v1/remedial-action/organization/${organizationId}/remedial-action/${remedialId}/edit-remedial-action`,
      method: "POST",
      body: {
        remedialAction: payload,
      },
    });
    if (res.data.status === "Success") {
      showNotification("Update Succesfully", "success");
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const updatesubclaiminfo = async (organizationId, claimId, payload) => {
  try {
    const res = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/case-claim/${claimId}/update-info`,
      method: "POST",
      body: payload,
    });
    if (res.data.status === "Success") {
      showNotification(res?.data?.data?.message, "success");

      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const createsubclaim = async (organizationId, agentId, payload) => {
  try {
    const res = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/agent/${agentId}/create-claim`,
      method: "POST",
      body: payload,
    });
    if (res.data.status === "Success") {
      showNotification(res?.data?.data?.message, "success");

      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const fetchremedialactions = async (organizationId, caseId) => {
  try {
    const res = await callApi({
      endpoint: `v1/remedial-action/organization/${organizationId}/case/${caseId}/get-remedial-action`,
      method: "GET",
    });
    if (res.data.status === "Success") {

      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const attemptTrigger = async (organizationId, caseId) => {
  try {
    const res = await callApi({
      endpoint: `v1/dispute-case/organization/${organizationId}/case/${caseId}/trigger-case`,
      method: "GET",
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {

    const errorMessage = e?.response?.data?.data?.message;

    if (errorMessage === "Case documents must include at least one for customer_evidence.") {
      showNotification("Case documents must include Primary Complaint", "error");
    }
    else if (errorMessage === "Case documents must include at least one for ombudsman_evidence.") {
      showNotification("Case documents must include Ombudsman Evidence", "error");
    }
    else {

      console.log(errorMessage);
      showNotification(errorMessage, "error");
    }
  }
};

export const attemptPlaybook = async (organizationId) => {
  try {
    const res = await callApi({
      endpoint: `v1/playbook/download-Playbook`,
      method: "POST",
      body: {
        organizationId,
      },
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};


export const getPlaybook = async (organizationId) => {
  try {
    const res = await callApi({
      endpoint: `v1/playbook/organizationId/${organizationId}/get-Playbook-List`,
      method: "POST",
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const getPlaybookCompaints = async (playbookId, organizationId) => {
  try {
    const res = await callApi({
      endpoint: `v1/playbook/${playbookId}/organizationId/${organizationId}/get-Playbook-Complaints`,
      method: "POST",
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};


export const fetchMemberList = async (organizationId, payload) => {
  try {
    const res = await callApi({
      endpoint: `v1/members/organization/${organizationId}/member-list`,
      method: "POST",
      body: { ...payload },
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const addMember = async (organizationId,agentId, payload) => {
  debugger
  try {
    const res = await callApi({
      endpoint: `v1/authentication/organization/${organizationId}/create-agent?=${agentId}`,
      method: "POST",
      body: { ...payload },
    });
    if (res.data.status === "Success") {
      showNotification("Add Member Successfully", "success");
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};



export const updateMember = async (organizationId,id, payload) => {
  
  try {
    const res = await callApi({
      endpoint: `v1/members/organization/${organizationId}/agent/${id}/update-agent`,
      method: "POST",
      body: { ...payload },
    });
    if (res.data.status === "Success") {
      showNotification("Updated Member Successfully", "success");
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};

export const deleteMember = async (organizationId,id, payload) => {
  try {
    const res = await callApi({
      endpoint: `v1/members/organization/${organizationId}/agent/${id}/remove-agent`,
      method: "POST",
      body: { ...payload },
    });
    if (res.data.status === "Success") {
      return res.data;
    } else {
      showNotification(res?.data?.data?.message, "error");
    }
  } catch (e) {
    showNotification(e?.response?.data?.message, "error");
  }
};




