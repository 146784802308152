export const ENV_PRODUCTION = "production";

export const ENV_STAGING = "staging";

export const TOKEN = "token";

export const AUTH_USER = "authUser";

export const ORGANIZATION_ID = "organization_id";

export const AGENT_ID = "agent_id";

export const CASE_ID = "case_id";
