import React from "react";

const FileViewer = ({ file }) => {
  const renderFile = () => {
    const { url, type } = file;
    switch (type) {
      case "pdf":
        return (
          <div>
            <embed src={url} type="application/pdf" className="w-full min-h-[700px]" title="PDF Viewer" />
          </div>
        );
      case "image":
        return (
          <img src={url} alt="Embedded content" className="w-full h-auto" />
        );
      case "doc":
        return (
          <iframe
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
              url
            )}`}
            className="w-full h-full"
            title="Embedded Office Document"
            frameBorder="0"
          />
        );

      case "video":
        return (
          <video controls className="w-full h-auto" title="Embedded Video">
            <source src={url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        );

      default:
        return (
          <div className="text-center text-gray-500">
            Unsupported file type.
          </div>
        );
    }
  };

  return <div className="w-full border border-gray-300">{renderFile()}</div>;
};

export default FileViewer;
