const storageService = {
    getItem: (key) => {
        try {
            const item = localStorage.getItem(key);
            return item ? JSON.parse(item) : null; // Parse the value if it's a JSON string
        } catch (error) {
            console.error("Error getting item from localStorage", error);
            return null;
        }
    },
    setItem: (key, value) => {
        try {
            localStorage.setItem(key, JSON.stringify(value)); // Stringify the value if it's an object
        } catch (error) {
            console.error("Error setting item in localStorage", error);
        }
    },
    removeItem: (key) => {
        try {
            localStorage.removeItem(key);
        } catch (error) {
            console.error("Error removing item from localStorage", error);
        }
    },
    clear: () => {
        try {
            localStorage.clear();
        } catch (error) {
            console.error("Error clearing localStorage", error);
        }
    },
};

export default storageService;
