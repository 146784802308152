import { Divider } from "../../../../components/Divider/Divider";
import { Image } from "../../../../components/Image/Image";
import { Switch } from "../../../../components/Switch/Switch";
import {
    ADD_REMEDIAL_ACTIONS,
    AI_GENERATED_TEXT,
    MONETARY_VALUE,
    REMEDIAL_ACTION,
    SUBCLAIM_VALID_DESC,
    USER_CONFIRMATION_TEXT,
} from "../../../../constants/casesConstants";
import Valid from "../../../../Images/CheckCircle.svg";
import inValid from "../../../../Images/XCircle.svg";
import sparkle from "../../../../Images/Sparkle.svg";
import collapse from "../../../../Images/Collapse.svg";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import EditButton from "../../../../components/EditButton/EditButton";

function SubClaims({
    handleshowClaim,
    handlRemedialModalOpen,
    handleSubClaimModalOpen,
    handleRemedialConformation,
    isshowclaim,
    claim,
    handleMonetaryConformation,
    handlEditMonetaryModalOpen,
    handlEditValidityModalOpen,
    handleCaseClaimValidate,
    remedialactionlist,
    monetaryValueState,
    selectedIndex,
    index,
    selectedClaim,
    handlcreateRemedialModalOpen,
}) {


    const getValidityState = (item) => {
       if (item === "Valid") {
           return (
               <div className="flex items-center gap-2 mb-2">
                   <Image className="w-[25px] h-[25px]" src={Valid}/>
                   <span className="font-normal text-[22px] text-[#589E67]">
                        {"Valid"}
                    </span>
                </div>
            )
        } else if (item === "Invalid") {
            return (<div className="flex items-center gap-2 mb-2">
                <Image className="w-[25px] h-[25px]" src={inValid} />
                <span className="font-normal text-[22px] text-[#AF4B4B]">
                    {"Invalid"}
                </span>
            </div>)
        }
    };

    return (
        <div data-testid="renediallist">
            <div className=" bg-white border border-gray-300 rounded-[4px] mt-2 ml-4 mr-4 ">
                <div>
                    <div className="flex items-center justify-between m-3 pr-5">
                        <div className="flex gap-3">
                            <div onClick={handleshowClaim} className="cursor-pointer">
                                <Image
                                    src={collapse}
                                    className={`ml-2 transform transition-transform duration-300 ${isshowclaim && selectedIndex === index ? "rotate-180" : ""
                                        }`}
                                        alt="collapse icon"
                                />
                            </div>

                            <h2 className="text-[18px] font-normal text-[#000000]">
                                {claim?.title}
                            </h2>
                            <Switch
                                intialrender={
                                    <span className="ml-3 text-[12px] text-gray-500">
                                        {"Edited on"} {"19-05-2000"}{" "}
                                    </span>
                                }
                                togglerenderitem={
                                    <span className="flex items-center gap-2 ml-3 text-[12px] text-blue-500">
                                        <Image src={sparkle} />
                                        {"AI Generated"}{" "}
                                    </span>
                                }
                            />
                        </div>
                        <EditButton onClick={() => handleSubClaimModalOpen(claim)} />
                    </div>
                    <Divider />
                </div>

                {selectedClaim.some(item => item === index) && (
                    <div>
                        <div className="flex flex-row justify-between px-8 py-4 bg-[#F9F9F9] w-[100%]">
                            <div>
                                <div className="flex items-center">
                                    <h3 className="text-[16px] font-bold text-black-900">
                                        Validity
                                    </h3>
                                    <Image className="w-[15px] h-[15px] ml-2" src={sparkle} />
                                    <span className="text-[12px] text-[#4976F4] ml-2">
                                        {AI_GENERATED_TEXT}
                                    </span>
                                </div>
                                <div className="">
                                    {getValidityState(claim.editValidity ? claim.editValidity : claim.validity)}
                                    <p className="mb-4 text-sm text-gray-600" >
                                        {claim?.validityText}
                                    </p>
                                </div>
                            </div>

                            <div className="w-[40%] flex justify-end ">
                                <div className="flex flex-col gap-4 self-end">
                                    <div
                                        className="flex justify-end"
                                        onClick={() => handlEditValidityModalOpen(claim)}
                                    >
                                        <EditButton />
                                    </div>

                                    <div
                                        className="flex items-center gap-2 cursor-pointer"
                                        onClick={() => handleCaseClaimValidate(claim?._id)}
                                    >
                                        <label
                                            htmlFor="valid-confirmation"
                                            className="text-sm text-gray-600 cursor-pointer"
                                        >
                                            {USER_CONFIRMATION_TEXT}
                                        </label>
                                        <Checkbox
                                            
                                            onChange={()=> {}}
                                            checked={claim?.isValidityConfirmedByAgent}
                                            className="w-3 h-3 cursor-pointer"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Divider />

                        <div className="flex flex-row justify-between px-8 py-4 bg-[#F9F9F9] w-[100%] ">
                            <div>
                                <div className="flex items-center">
                                    <h3 className="text-[16px] font-bold text-black-900">
                                        {MONETARY_VALUE}
                                    </h3>
                                    <Image className="w-[15px] h-[15px] ml-2" src={sparkle} />
                                    <span className="text-[12px] text-[#4976F4] ml-2">
                                        {AI_GENERATED_TEXT}
                                    </span>
                                </div>
                                {claim?.editMonetaryValue && claim?.editMonetaryValueReason ? (
                                    <div className="mb-6 after:">
                                        <p className="mb-2 text-2xl text-gray-700">
                                            {"£"}
                                            {claim?.editMonetaryValue}
                                        </p>
                                        <p className="mb-4 text-sm text-gray-600">
                                            {claim?.editMonetaryValueReason}
                                        </p>
                                    </div>
                                )
                                    : (
                                        <div className="mb-6 after:">
                                            <p className="mb-2 text-2xl text-gray-700">
                                                {"£"}
                                                {claim?.monetaryValue}
                                            </p>
                                            <p className="mb-4 text-sm text-gray-600">
                                                {claim?.monetaryValueReason}
                                            </p>
                                        </div>
                                    )
                                }
                            </div>

                            <div className="w-[40%] flex justify-end">
                                <div className="flex flex-col self-end gap-4">
                                    <div
                                        className="flex justify-end"
                                        onClick={() => handlEditMonetaryModalOpen(claim)}
                                    >
                                        <EditButton />
                                    </div>

                                    <div
                                        className="flex items-center gap-2 "
                                        onClick={() => handleMonetaryConformation(claim, claim?._id, claim.isMonetaryValidityConfirmedByAgent)}
                                    >
                                        <label
                                            htmlFor="valid-confirmation"
                                            className="text-sm text-gray-600 cursor-pointer"
                                        >
                                            {USER_CONFIRMATION_TEXT}
                                        </label>
                                        <Checkbox
                                            
                                            onChange={()=> {}}
                                            checked={claim.isMonetaryValidityConfirmedByAgent}
                                            className="w-3 h-3 bg-black cursor-pointer"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Divider />
                        <div >
                        {remedialactionlist.length > 0 && (
                            <div className="bg-[#F9F9F9]" >
                                <h3 className="text-[16px] font-bold text-black-900 px-8 py-4">
                                    {REMEDIAL_ACTION}
                                </h3>
                                {remedialactionlist?.map((action, index) => (
                                    <div
                                        className="flex flex-row justify-between px-8 py-4 w-[100%]"
                                        key={index}
                                    >
                                        <div>
                                            <div className="flex items-center">
                                                <span className="text-[22px] text-black-800 flex items-center">
                                                    <span className="w-[7px] h-[7px] bg-black rounded-full inline-block mr-2"></span>
                                                    {action.title}
                                                </span>
                                                <Image
                                                    className="w-[15px] h-[15px] ml-2"
                                                    src={sparkle}
                                                />
                                                <span className="text-[12px] text-[#4976F4] ml-2">
                                                    {AI_GENERATED_TEXT}
                                                </span>
                                            </div>
                                            <div className="mb-6 after:">
                                                <p className="mb-4 ml-4 text-sm text-gray-600">
                                                    {action.description}
                                                </p>
                                            </div>
                                        </div>

                                        <div className="w-[40%] flex justify-end">
                                            <div className="flex flex-col self-end gap-4">
                                                <div>
                                                    <EditButton
                                                        onClick={() => handlRemedialModalOpen(action)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        </div>
                        <div className="bg-[#F9F9F9]">
                            <div className="flex justify-between py-5 px-8">
                                <div className={"cursor-pointer"}
                                    onClick={() => handlcreateRemedialModalOpen(claim?._id)}>
                                    <p className="mb-2 text-[15px] text-[#000000] font-normal">
                                        {ADD_REMEDIAL_ACTIONS}
                                    </p>
                                </div>
                                {remedialactionlist.length > 0 &&
                                    <div className="flex items-center gap-2"
                                        onClick={() => handleRemedialConformation(claim, claim?._id, monetaryValueState[claim._id] !== "validate")}
                                    >
                                        <label
                                            htmlFor="valid-confirmation"
                                            className="text-sm text-gray-600 cursor-pointer"
                                        >
                                            {USER_CONFIRMATION_TEXT}
                                        </label>
                                        <Checkbox 
                                        onChange={()=> {}}
                                        checked={claim.isRemedialActionConfirmedByAgent}
                                            className="w-3 h-3 cursor-pointer" />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default SubClaims;
