import React from "react";
import Icon from "../../../components/Icon/Icon";

const Profile = ({ userInfo }) => {

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    alert('Copied to clipboard!');
  };


  return (
    <div className="flex flex-col items-center text-center mx-5 my-5 ">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">Your Profile Information</h2>
      <div className="mt-5 p-6 border-2 border-gray-300 rounded-lg shadow-lg bg-white w-[70%]">
        <div className="grid grid-cols-2 gap-4">
          <div className="flex justify-start">
            <span className="text-md text-gray-800">Name:</span>
          </div>
          <div className="flex justify-start">
            <span className="text-md text-gray-700">{userInfo?.contactName}</span>
          </div>

          <div className="flex justify-start">
            <span className="text-md text-gray-800">Email:</span>
          </div>
          <div className="flex justify-start">
            <span className="text-md text-gray-700">{userInfo?.contactEmail}</span>
          </div>

          <div className="flex justify-start">
            <span className="text-md text-gray-800">Phone:</span>
          </div>
          <div className="flex justify-start">
            <span className="text-md text-gray-700">{userInfo?.contactPhone}</span>
          </div>

          <div className="flex justify-start">
            <span className="text-md text-gray-800">Organization ID:</span>
          </div>
          <div className="flex justify-start items-center flex-wrap">
            <p className="text-md text-gray-700 w-full sm:w-auto break-all">{userInfo?.organizationId?._id}</p>
            <button
              onClick={() => handleCopy(userInfo?.organizationId?._id)}
              className="ml-2 text-gray-500 hover:text-gray-700"
              aria-label="Click to copy"
            >
              <Icon type="DocumentCopyIcon" />
            </button>
          </div>

          <div className="flex justify-start">
            <span className="text-md text-gray-800">Organization Name:</span>
          </div>
          <div className="flex justify-start">
            <span className="text-md text-gray-700">{userInfo?.organizationId?.registeredName}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
