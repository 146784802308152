import React from "react";
import { bannerText } from "../../constants/bannerConstants";


const BannerComponent = () => {
    return (
        <div className="w-1/2 bg-[#000000] flex flex-col justify-start items-center p-8 text-white">
            <h1 className="font-inter text-[32px] font-medium leading-[120%] text-center max-w-[410px] mt-[62px]">
                {bannerText.heading}
            </h1>
            <p className="mt-4 font-inter text-[14px] font-normal leading-[150%] text-center text-[#F2F2F2]">
                {bannerText.subheading}
            </p>
        </div>
    )
}

export default BannerComponent;