import { Divider } from "../Divider/Divider";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Image } from "../Image/Image";
import bellIcon from "../../Images/Bell.svg";
import warningIcon from "../../Images/WarningOctagon.svg";
import downarrowIcon from "../../Images/DownArrow.svg";
import { content } from "../../constants/headerConstants";
import {
  AGENT_ID,
  ORGANIZATION_ID,
  TOKEN,
  AUTH_USER,
} from "../../constants/enum";
import { fetchAgentInfo } from "../../services/coreService";
import { attemptLogout } from "../../services/loginUtility";
import storageService from "../../services/storageService";
import { useNavigate } from "react-router-dom";
import { SIGNIN_ROUTE } from "../../constants/routeEnums";

const Header = () => {
  const [dropdownActive, setDropdownActive] = useState(false);
  const agentId = storageService.getItem(AGENT_ID);
  const organizationId = storageService.getItem(ORGANIZATION_ID);
  const [userInfo, setUserInfo] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async (organizationId, agentId) => {
      const res = await fetchAgentInfo(organizationId, agentId);
      if (res?.status === "Success") {
        const { agent } = res.data;
        setUserInfo(agent);
        storageService.setItem(AUTH_USER, agent);
      }
    };

    if (organizationId && agentId) fetchData(organizationId, agentId);
  }, [organizationId, agentId]);

  return (
    <>
      <div className="flex w-[100%-17.22%] h-[72px] px-8 justify-end items-center gap-2.5">
        <div className="flex">
          <div className="flex items-center justify-center gap-1 p-1 px-2 rounded-[4px] bg-[#FBF4EC] border-[#FBF4EC]">
            <Image src={warningIcon} alt="Warning Icon" />
            <span className="font-inter text-sm font-normal leading-[150%] text-[#D28E3D]">
              {content.heading}
            </span>
            <a
              className="font-inter text-sm font-normal leading-[150%] text-[#4976F4] underline decoration-solid decoration-auto decoration-[#4976F4] text-underline-offset-auto"
              href={content.target}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="External link for more info"
            >
              {content.link}
            </a>
          </div>
          <div
            className="flex w-[39px] p-2 items-center gap-3 self-stretch mr-8 ml-8 cursor-pointer"
            data-testid="bell-icon"
            aria-label="Notifications"
          >
            <Image src={bellIcon} />
          </div>

          <div
            className="flex items-center gap-3 cursor-pointer"
            onClick={() => setDropdownActive(!dropdownActive)}
            aria-haspopup="true"
            aria-expanded={dropdownActive}
            data-testid="user-dropdown"
          >
            {userInfo ? (
              <span >{userInfo.contactName.split(" ")[0]}</span>
            ) : (
              <span>Loading...</span>
            )}
            <Image src={downarrowIcon} />
          </div>
        </div>
      </div>

      {dropdownActive && (
        <div
          className="fixed top-[40px] right-0 bg-white w-full max-w-max px-8 py-4 m-4 z-20 shadow-md border border-t-gray-100"
          aria-label="User menu"
          data-testid="user-menu"
        >
          <Link
            to={SIGNIN_ROUTE}
            onClick={() => attemptLogout()}
            data-testid="logout-link"
            aria-label="Logout"
          >
            Logout
          </Link>
        </div>
      )}
    </>
  );
};

export default Header;
