const TextAreaForm = ({
  value,
  handleChange,
  label,
  id,
  placeholder,
  errorMessage,
  isValid,
}) => {
  return (
    <div className="w-full max-w-md mx-auto mt-4">
      <label
        htmlFor={id}
        className="block mb-2 text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <textarea
        id={id}
        placeholder={placeholder}
        className={`w-full px-4 py-2 text-gray-700 placeholder-gray-400 transition duration-200 ease-in-out border rounded-lg focus:outline-none focus:ring-2 ${
          isValid
            ? "border-gray-300 focus:ring-gray-500"
            : "border-red-400 focus:ring-red-500"
        }`}
        value={value}
        onChange={handleChange}
        aria-invalid={!isValid}
        rows="4"
      ></textarea>
      {!isValid && <p className="mt-2 text-sm text-red-400">{errorMessage}</p>}
    </div>
  );
};

export default TextAreaForm;
