import axios from "axios";
import storageService from "./storageService";
import {attemptLogout} from "./loginUtility";

const CORE_API = process.env.REACT_APP_CORE_API || ``;

export default async function callApi({
  endpoint,
  method,
  body,
  service = "core",
}) {
  try {
    let authUser = storageService.getItem("token")
      ? storageService.getItem("token")
      : null;

    let headers = {
      "content-type": "application/json",
    };

    if (authUser) {
      let token = authUser;
      if (token && token !== "") {
        // @ts-ignore
        headers.Authorization = `Bearer ${token}`;
      }
    }
    let response =  await axios({
      url: `${CORE_API}/${endpoint}`,
      method,
      data: body,
      headers,
      timeout: 300000,
    });
    return response;
  } catch (e) {
    if (e.status === 403) {
      attemptLogout()
    }
    throw e;
  }
}
