import storageService from "./storageService";
import axios from "axios";
import { showNotification } from "../components/Toastify/Toast";
import {attemptLogout} from "./loginUtility";

const CORE_API = process.env.REACT_APP_CORE_API || ``;

export const attemptUploader = async (organizationId, caseId, tags, file) => {

  let headers = {};

  try {

    let authUser = storageService.getItem("token")
      ? storageService.getItem("token")
      : null;

    if (authUser) {
      let token = authUser;
      if (token && token !== "") {
        // @ts-ignore
        headers.Authorization = `Bearer ${token}`;
      } else {
        throw "No Token Found!";
      }
    }
    const formData = new FormData();
    formData.append("documents", file);

    const url = `${CORE_API}/v1/disputecases/organization/${organizationId}/case/${caseId}/upload-document?evidence_category=${tags}`;

    let response = await axios.post(url, formData, { headers });
    return response;
  } catch (error) {
    if (error.status === 403) {
      return attemptLogout()
    }
    showNotification(error?.response?.data?.data?.message, "error")
    console.error("Error uploading file:", error);
  }
};
