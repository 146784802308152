import React from "react";
import { Divider } from "../Divider/Divider";

const Overlay = ({
  isOpen,
  onClose,
  children,
  title = "Title", 
  className = "",
  position = "center", 
}) => {
  if (!isOpen) return null; 

  const positionClasses = {
    top: "items-start justify-center",
    right: "items-center justify-end",
    bottom: "items-end justify-center",
    left: "items-center justify-start",
    center: "items-center justify-center",
  };

  return (
    <div
      className={`fixed inset-0 z-50 flex ${positionClasses[position]} bg-black bg-opacity-50`}
      onClick={onClose}
      role="dialog"
    >
      <div
        className={`relative w-full max-w-lg bg-white rounded-[4px] shadow-sm ${className}`}
        onClick={(e) => e.stopPropagation()} 
      >
        <div className="flex items-center px-6 py-4 justify-between">
          <h2 className="text-lg font-semibold">{title}</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            ✕
          </button>
        </div>
        <Divider />
        <div className="pt-4 mb-5"
    
        >{children}</div>
      </div>
    </div>
  );
};

export default Overlay;
