import React from "react";
import Icon from "../Icon/Icon";

const Timeline = ({ events = [] }) => {

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    return (
        <div className="flex flex-col mt-[16px]">
            {events?.map((event, index) => (
                <div key={index} className="flex space-x-4">

                    <div className="flex flex-col items-center">
                        <Icon type="EllipseIcon" className="w-[9px] h-[9px]" />
                        {index < events.length - 1 && (
                            <div className="w-[1px] h-[100%]  bg-[#4976F4]"></div>
                        )}
                    </div>

                    <div className="flex flex-col pb-[16px]">

                        <p className="text-[#AFAFAF] text-xs font-normal leading-[1.4] mb-[2px]">
                            {formatDate(event.timelineDate)}
                        </p>

                        <p className="text-black font-inter text-base font-medium leading-[1.5]">
                            {event.title}
                        </p>

                        <p className="text-black text-base font-normal leading-[1.5]">
                            {event.description}
                        </p>

                        {event.link && (
                            <div className="flex items-center space-x-1">
                                <Icon type="FileTextIcon" className="h-[16px] w-[16px] text-blue-500" />
                                <a
                                    href={event.link.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-[#4976F4] text-sm font-normal leading-[1.5] underline decoration-solid decoration-skip-ink-none"
                                >
                                    {event.link.text}
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Timeline;
