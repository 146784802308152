import React, { useState } from "react";
import { Image } from "../Image/Image";

const SelectDropdown = ({
  options,
  value,
  onChange,
  label,
  placeholder,
  collapseIcon,
  textcolor
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOptionClick = (selectedValue) => {
    onChange(selectedValue);
    setIsOpen(false);
  };

  const selectedOption = options.find((option) => option.value === value);

  return (
    <div className="relative">
      {label && (
        <label className="block mb-2 text-sm font-medium text-gray-700">
          {label}
        </label>
      )}
      <div
        className="w-full px-2 py-1 rounded-sm text-gray-700 bg-white border border-gray-300 rounded-sm shadow-sm cursor-pointer focus:ring-2 focus:ring-blue-500 flex items-center"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {selectedOption ? (
          <div className="flex items-center mr-2">
            <Image
              src={selectedOption.icon}
              alt={selectedOption.label}
              width={20}
              height={20}
              className="mr-2"
            />
            <p className="text-[14px]" style={{color: textcolor ? textcolor : "black"}}>{selectedOption.label}</p>
          </div>
        ) : (
          <p>{placeholder}</p>
        )}

        <Image
          src={collapseIcon}
          alt="Collapse Icon"
          width={20}
          height={20}
          className={`ml-auto transform transition-all duration-300 ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </div>

      {isOpen && (
        <div className="absolute z-10 w-full bg-white border border-gray-300 rounded-sm shadow-lg">
          {options.map((option, index) => (
            <div
              key={index}
              className="flex items-center px-2 py-1 hover:bg-gray-100 cursor-pointer"
              onClick={() => handleOptionClick(option.value)}
            >
              <Image
                src={option.icon}
                alt={option.label}
                width={20}
                height={20}
                className="mr-2"
              />
              <p className="text-[14px]" >{option.label}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectDropdown;
