import React from "react";

const List = ({ items, renderItem, className, handleClick, sortBy }) => {

  const sortedItems = sortBy ? [...items].sort(sortBy) : items;

  return (
    <ul>
      {sortedItems.map((item, index) => (
        <li
          key={index}
          className={className ? className : ""}
          onClick={() => handleClick(item)}
        >
          {renderItem ? renderItem(item, index) : item}
        </li>
      ))}
    </ul>
  );
};

export { List };
