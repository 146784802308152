import React from 'react';
import { ThreeBounce } from 'better-react-spinkit';

export function Loader({ className, color, size }) {

  return (
    <div className={className ? className : "text-center"} data-testid="spinner">
      <ThreeBounce color={color ? color : 'black'} size={size ? size : 15} />
    </div>
  );
}

export default Loader;
