import React from "react";

const Checkbox = ({ checked, onChange, className }) => {

    const defaultClasses = "h-5 w-5 text-blue-600 border-gray-300 rounded-sm focus:ring-2 focus:ring-blue-500";

    return (
        <input
            type="checkbox"
            checked={checked}
            onChange={onChange}
            className={className ? className : defaultClasses}
        />
    );
};

export default Checkbox;
