import Valid from "../Images/CheckCircle.svg"
import inValid from "../Images/XCircle.svg"


export const content = {
  heading: " Netflix Bundling Complaint",
  name: "Alex Smith",
};

export const tabs = {
  overview: "Overview",
  documents: "Documents",
  timeline: "Timeline",
  claims: "Claims",
  response: "Response",
};

export const uploadContent = {
  heading: "You are using a demo of Dispute Assessor.",
  para: "For accurate testing, please use one of the 10 provided test packs.",
};


export const SUBCLAIM_HEADING = "Compensation for Netflix bundling error"
export const SUBCLAIM_VALID_DESC = "The claim is valid because our internal customer service reports evidence that there was no response for 12 weeks."
export const USER_CONFORMATION_TEXT = "User Confirmation"
export const MONETARY_VALUE = "Monetary Value"
export const DECISION_TEXT = "Decision"
export const PLAYBOOK_DESC = "The playbook suggests that £200 compensation should be offered for a bundling error that results in the customer being charged."
export const REMEDIAL_ACTION = "Remedial Actions"
export const CREDIT_ACCOUNT_TEXT = "Wipe customer’s credit account"
export const AI_GENERATED_TEXT = "AI Generated"
export const CREDIT_ACCOUNT_DESC = "The playbook recommends wiping the customer’s credit account."
export const DISCOUNT_TEXT = "Apply 20% discount to customer’s bill for three months"
export const PLAYBOOK_DISCOUNT_DESC = "The playbook recommends applying a discount to the customer’s bills for three months."
export const ADD_REMEDIAL_ACTIONS = "+ Add a remedial action"
export const ISSUE_APOLOGY_TEXT = "Issue apology "
export const ISSUE_APOLOGY_DESC = "The playbook recommends sending the customer an apology letter for poor customer service and bundling errors. "
export const ADDITIONAL_REMEDIAL_ACTION_TEXT = "Additional Remedial Action"
export const USER_CONFIRMATION_TEXT = "User Confirmation"
export const decisionoptions = [
  { label: "Make Offer", value: "make_offer", icon: Valid },
  { label: "Submit Defence", value: "submit_defence", icon: inValid },
];


export const validityoptions = [
  { label: "Valid", value: "validate", icon: Valid },
  { label: "InValid", value: "invalidate", icon: inValid },
];

export const EscalateCase = [
  { label: "Legal", value: "Legal" },
  { label: "Dispute Management", value: "Dispute Management" },
  { label: "Customer Service", value: "Customer Service" },
];


export const memberRole = [
  { label: "Admin", value: "admin"},
  { label: "Reader", value: "reader"},
  { label: "Contributor", value: "contributor"},
];