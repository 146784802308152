import {
  ROOT_ROUTE,
  RESET_PASSWORD_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  CONTACT_US_ROUTE,
  LOGIN_ROUTE,
  SIGNIN_ROUTE,
  SETTINGS_ROUTE,
  CASELIST_ROUTE,
  CASES_ROUTE,
  CASE_UPLOAD_ROUTE,
  SIGNUP_ROUTE,
} from "../constants/routeEnums";
import HomePage from "../modules/utilities/HomePage/HomePage";
import ContactUsPage from "../modules/utilities/ContactUsPage/ContactUsPage";
import SignupPage from "../modules/authentication/SignupPage/SignupPage";
import LoginPage from "../modules/authentication/LoginPage/LoginPage";
import ForgotPasswordPage from "../modules/authentication/ForgotPassword/ForgotPassword";
import ResetPasswordPage from "../modules/authentication/ResetPassword/ResetPassword";
import Settings from "../modules/utilities/Settings/Settings";
import CaseList from "../modules/utilities/CaseList/CaseList";
import Cases from "../modules/utilities/Cases/Cases";
import Caseupload from "../modules/utilities/Caseupload/Caseupload";

import {
  HOME_PAGE_TITLE,
  CASELIST_TITLE,
  SETTINGS_TITLE,
  CONTACT_US_TITLE,
  CASES_TITLE,
  RESET_PASSWORD_TITLE,
  FORGOT_PASSWORD_TITLE,
  SIGNIN_TITLE,
  CASE_UPLOAD_TITLE,
} from "../constants/pageTitleConstants";

const privateRoutes = [
  { path: ROOT_ROUTE, component: CaseList, title: HOME_PAGE_TITLE },
  { path: CASELIST_ROUTE, component: CaseList, title: CASELIST_TITLE },
  { path: SETTINGS_ROUTE, component: Settings, title: SETTINGS_TITLE },
  { path: CONTACT_US_ROUTE, component: ContactUsPage, title: CONTACT_US_TITLE },
  { path: CASES_ROUTE, component: Cases, title: CASES_TITLE },
  { path: CASE_UPLOAD_ROUTE, component: Caseupload, title: CASE_UPLOAD_TITLE },
];

const publicRoutes = [
  {
    path: RESET_PASSWORD_ROUTE,
    component: ResetPasswordPage,
    title: RESET_PASSWORD_TITLE,
  },
  { path: LOGIN_ROUTE, component: LoginPage },
  {
    path: FORGOT_PASSWORD_ROUTE,
    component: ForgotPasswordPage,
    title: FORGOT_PASSWORD_TITLE,
  },
  { path: SIGNIN_ROUTE, component: SignupPage, title: SIGNIN_TITLE },
];

export { privateRoutes, publicRoutes };
