import React, { useState } from "react";
import Icon from "../Icon/Icon";

const Switch2_0 = ({
    initialState,
    date = "",
    className = "",
    iconClassName = "",
}) => {
    const [isToggled, setIsToggled] = useState(initialState);

    const handleToggle = () => {
        setIsToggled(!isToggled);
    };

    return (
        <div
            className={`flex items-center cursor-pointer ${className}`}
            onClick={handleToggle}
            role="switch"
            aria-checked={isToggled}
            tabIndex="0"
        >
            {isToggled ? (
                <div className="flex items-center gap-2">
                    <span className="text-[#4976F4] font-inter text-xs font-normal leading-[140%]">
                        AI Generated
                    </span>
                    <Icon type="SparkleIcon" className="h-4 w-4" />
                </div>
            ) : (
                <span className="text-sm text-gray-500">Edited on {date}</span>
            )}
        </div>
    );
};

export default Switch2_0;
