import React from "react";
import { Navigate } from "react-router-dom";
import { SIGNIN_ROUTE } from "../constants/routeEnums";
import { TOKEN } from "../constants/enum";
import storageService from "../services/storageService";

const AuthProtected = ({ children }) => {
  if (!storageService.getItem(TOKEN)) {
    return <Navigate to={{ pathname: SIGNIN_ROUTE }} />;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthProtected;
