import React from 'react';
import Switch2_0 from '../../../../components/SwitchV2/SwitchV2';

const CaseResponse = ({ caseHighlights, letterData, caseHighlightsDate, remedialData }) => {



    return (
        <div class="flex flex-col space-y-4 pt-[24px] mb-3">
            {(!letterData || letterData?.length === 0) ? (
                <div className="flex items-center justify-center h-[50vh] text-center text-gray-500">
                    <p>No response data available at the moment.</p>
                </div>
            )
                :
                <div>
                    <div class="flex flex-row justify-between">
                        <div className="w-full pb-[12px] px-[32px]">
                            <div className="flex space-x-2 items-center w-full">
                                <h6 className="text-[var(--Color-Tokens-Content-Dark-Primary, #000)] font-inter text-[16px] font-normal leading-[150%] whitespace-nowrap">
                                    Key Case Highlights
                                </h6>
                                <div className="flex items-center gap-2">
                                    <Switch2_0 initialState="false" date={caseHighlightsDate} />
                                </div>
                            </div>
                            <ul className="list-disc pl-5 text-[#727272] font-inter text-[14px] font-normal leading-[150%] mt-4 overflow-y-auto max-h-32">
                                {typeof caseHighlights === "string" ? (
                                    <p>{caseHighlights}</p>
                                ) : (
                                    caseHighlights?.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))
                                )}
                            </ul>
                        </div>

                        <div className="w-full pt-[8px] pb-[12px] px-[32px]">
                            <div className="flex space-x-2 items-center w-full">
                                <h6 className="text-[var(--Color-Tokens-Content-Dark-Primary, #000)] font-inter text-[16px] font-normal leading-[150%]">
                                    Remediations
                                </h6>
                                <div className="flex items-center gap-2">
                                    <Switch2_0 initialState="false" />
                                </div>
                            </div>
                            <ul className="list-disc pl-5 text-[#727272] font-inter text-[14px] font-normal leading-[150%] mt-4 overflow-y-auto max-h-32">
                                {remedialData?.map((item, index) => (
                                    <li key={index}>{item.title}</li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div class="pt-[12px] pb-[24px] px-[32px]">
                        <div class="pt-[16px] pb-[16px] text-ellipsis overflow-hidden text-black font-inter text-lg font-medium leading-[150%] border-t border-b border-[#E4E4E4]">
                            Draft Offer Letter
                        </div>

                        <div className='pt-[16px] pb-[16px] px-[16px] py-[16px]'>
                            <div className="flex items-center gap-2">
                                <Switch2_0 initialState="false" />
                            </div>
                        </div>

                        <div className="text-[#727272] text-xl font-normal leading-[27px] whitespace-pre-line">
                            <p> {letterData} </p>
                        </div>

                    </div>
                </div>
            }
        </div>

    )
}

export default CaseResponse;