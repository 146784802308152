import memberIcon from "../Images/member_icon.svg";
import profileIcon from "../Images/profile_icon.svg";
import playbookIcon from "../Images/playbook_icon.svg";
import rulesIcon from "../Images/rules_icon.svg";

export const settingContent = [
  { label: "Profile", icon: profileIcon },
  { label: "Members", icon: memberIcon },
  { label: "Playbooks", icon: playbookIcon },
  { label: "Rules", icon: rulesIcon },
];

export const playbook = [
  { label: "TeleWave Playbook", value: "TeleWave Playbook" },
];
