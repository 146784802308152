import React from "react";

const Label = ({ text, htmlFor, className, children, style = {} }) => {
    return (
        <label
            htmlFor={htmlFor}
            className={`${className ? className : "text-gray-700 font-medium"}`}
            style={style}
        >
            {text}
            {children}
        </label>
    );
};

export default Label;
