import React, { useState } from "react";
import EyeIcon from "../Icon/Icon";

const InputPassword = ({
  id,
  name,
  placeholder,
  className,
  onChange,
  value,
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevState) => !prevState);
  };

  return (
    <div
      className={`mt-2 flex items-center gap-3  border rounded-[4px] border-gray-300 bg-white ${className}`}
    >
      <input
        id={id}
        name={name} // Added name for form handling
        type={passwordVisible ? "text" : "password"}
        placeholder={placeholder}
        onChange={onChange}
        value={value} // Added value for controlled input
        className="flex-1 outline-none w-full text-[14px] pl-[10px] pt-[10px] pb-[10px] font-normal leading-[150%] text-[#000] bg-white"
      />

      <EyeIcon
        type="EyeIcon"
        className="cursor-pointer pr-[10px] "
        onClick={togglePasswordVisibility}
        alt={passwordVisible ? "Hide Password" : "Show Password"} // Improved accessibility
      />
    </div>
  );
};

export default InputPassword;
