import React, { useState } from "react";
import { Image } from "../Image/Image";
import sparkleIcon from "../../Images/Sparkle.png";
const Switch = ({
  checked,
  onChange,

  className = "",
  togglerenderitem,
  intialrender
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleToggle = () => {
    const newCheckedState = !isChecked;
    if (onChange) {
      onChange(newCheckedState);
    }
    setIsChecked(newCheckedState);
  };

  return (
    <div
      className={`flex items-center cursor-pointer ${className}`}
      onClick={handleToggle}
      role="switch"
      aria-checked={isChecked}
      tabIndex="0"
    >
      {isChecked ? (

        <div>
          {togglerenderitem}
        </div>
      ) : (
        <div>
          {intialrender}
        </div>
      )}
    </div>
  );
};

export { Switch };
