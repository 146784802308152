// utils/sortCaseList.js
import { DISPUTE_CASE_STATUS_COMPLETED, DISPUTE_CASE_STATUS_FAILED, DISPUTE_CASE_STATUS_UPLOAD_PENDING, DISPUTE_CASE_STATUS_PROGESS, DISPUTE_CASE_STATUS_SENT_FOR_PROCESSING } from "../constants/disputeCaseStatusConstants";

export const statusPriority = {
    [DISPUTE_CASE_STATUS_UPLOAD_PENDING]: 1,
    [DISPUTE_CASE_STATUS_SENT_FOR_PROCESSING]: 2,
    [DISPUTE_CASE_STATUS_FAILED]: 3,
    [DISPUTE_CASE_STATUS_PROGESS]: 4,
    [DISPUTE_CASE_STATUS_COMPLETED]: 5,
};

export const sortByStatusPriority = (a, b) => {
    const priorityA = statusPriority[a.status] || Infinity;
    const priorityB = statusPriority[b.status] || Infinity;
    return priorityA - priorityB;
};


export const sortByUpdatedTime = (a, b) => {

    const dateA = new Date(a.updatedAt);
    const dateB = new Date(b.updatedAt);
    return dateB - dateA; // Descending order
};


export const sortByTimeRemaining = (a, b) => {

    const deadlineA = new Date(a.caseDeadlineDate);
    const deadlineB = new Date(b.caseDeadlineDate);

    const remainingA = deadlineA - new Date();
    const remainingB = deadlineB - new Date();

    return remainingA - remainingB; // Ascending order
};
