import React from "react";
import { useTabContext } from "./TabContext";

const NavItem = ({ as: Component = "li", children, className, eventKey }) => {
  const { activeTab, changeTab } = useTabContext();

  return (
    <>
      <Component
        className={`${className} ${eventKey === activeTab ? "active" : ""}`}
      >
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            // Add the onClick handler to the child element
            return React.cloneElement(child, {
              onClick: () => changeTab(eventKey), // Pass eventKey to changeTab
            });
          }
          return null;
        })}
      </Component>
    </>
  );
};

export { NavItem };
