export const ROOT_ROUTE = "/";

export const RESET_PASSWORD_ROUTE = "/reset-password/:token";

export const FORGOT_PASSWORD_ROUTE = "/forgot-password";

export const CONTACT_US_ROUTE = "/contact-us";

export const LOGIN_ROUTE = "/login";

export const SIGNIN_ROUTE = "/signin";

export const SIGNUP_ROUTE = "/signup";

export const SETTINGS_ROUTE = "/settings";

export const CASELIST_ROUTE = "/case-list";

export const CASES_ROUTE = "/cases/:case_id";

export const CASE_UPLOAD_ROUTE = "/case-upload";
