import React, { createContext, useContext, useEffect, useState } from "react";

const TabContext = createContext(undefined);

export const useTabContext = () => {
  const context = useContext(TabContext);
  if (context === undefined) {
    throw new Error("useTabContext must be used within a TabContextProvider");
  }
  return context;
};

export const TabContextProvider = ({ children, defaultActiveKey }) => {
  const [activeTab, setActiveTab] = useState(defaultActiveKey);

  const changeTab = (newTab) => {
    setActiveTab(newTab);
  };

  useEffect(() => {
    setActiveTab(defaultActiveKey);
  }, [defaultActiveKey]);

  return (
    <TabContext.Provider value={{ activeTab, setActiveTab, changeTab }}>
      {children}
    </TabContext.Provider>
  );
};
