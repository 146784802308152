import React from "react";
import { TabContextProvider } from "./TabContext";

const TabContainer = ({ children, defaultActiveKey }) => {
  return (
    <TabContextProvider defaultActiveKey={defaultActiveKey}>
      {children}
    </TabContextProvider>
  );
};

export { TabContainer };
