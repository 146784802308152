import React from "react";
import edit from "../../../../../Images/editicon.svg";
import trash from "../../../../../Images/trashicon.svg";
import Button from "../../../../../components/Button/Button";

function MemberCard({ memberlist, removeMember, handleUpdateMember }) {


  const getCreatedByName = (member) => {
    if (member?.createdBy === null || member?.createdBy === undefined) {
      return `${member?.firstName} ${member?.lastName}`;
    } else {
      return member?.createdBy?.contactName;
    }
  };


  return (
    <div>
      <Button>Add Member</Button>
      <div>
        <table className="w-full border-collapse">
          <thead className="bg-gray-100 border-b-2 border-gray-400 shadow-sm">
          <tr className="text-left">
              <th className="text-[#000] font-normal p-2">Name</th>
              <th className="text-[#000] font-normal p-2">Email</th>
              <th className="text-[#000] font-normal p-2">Contact Number</th>
              <th className="text-[#000] font-normal p-2">Created By</th>
              <th className="text-[#000] font-normal p-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {memberlist.map((member, index) => (
              <tr key={index} className="even:bg-gray-100">
                <td className="p-2">
                  <div>
                    <span>{`${member?.firstName} ${member?.lastName}`}</span>
                    <p className="text-gray-500 text-sm">{member?.role}</p>
                  </div>
                </td>
                <td className="p-2">{member?.contactEmail}</td>
                <td className="p-2">({member?.countryCode})-{member?.contactPhone}</td>
                <td className="p-2"> {getCreatedByName(member)}</td>
                <td className="p-2 flex gap-5">
                  <button
                    onClick={() => handleUpdateMember(member)}
                    className="p-1.5 text-white bg-transparent border-none cursor-pointer"
                  >
                    <img src={edit} alt="Edit" />
                  </button>
                  <button
                    onClick={() => removeMember(member?._id)}
                    className="p-1.5 text-white bg-transparent border-none cursor-pointer"
                  >
                    <img src={trash} alt="Delete" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MemberCard;

