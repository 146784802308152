import { Image } from "../../../components/Image/Image";
import Uploader from "../../../components/Uploader/Uploader";
import { attemptTrigger, getCaseDocuments } from "../../../services/coreService";
import warningIcon from "../../../Images/WarningOctagon.svg";
import { uploadContent } from "../../../constants/casesConstants";
import React, { useEffect, useState } from "react";
import Label from "../../../components/Label/Label";
import arrowRight from "../../../Images/CaretRight.svg";
import { useNavigate } from "react-router-dom";
import { CASES_ROUTE } from "../../../constants/routeEnums";
import { CASE_ID, ORGANIZATION_ID } from "../../../constants/enum";
import storageService from "../../../services/storageService";
import Loader from "../../../components/Loaders/Loader";
import { EVIDENCE_CATEGORY_CUSTOMER } from "../../../constants/disputeCaseEnums";

const Caseupload = () => {
  const [groupedDocuments, setGroupedDocuments] = useState({});
  const [categories, setCategories] = useState([]);
  const [countComplain, setCountComplain] = useState(0);
  const [countOmbudsman, setCountOmbudsmaan] = useState(0);
  const [countInternal, setCountInternal] = useState(0);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isTriggeringButton, setTriggerButton] = useState(false);
  const caseId = storageService.getItem(CASE_ID);
  const organizationId = storageService.getItem(ORGANIZATION_ID);

  useEffect(() => {
    const fetchDocuments = async () => {
      setLoading(true);
      try {
        const pageNum = 1;
        const pageSize = 100;
        const filters = {};

        const response = await getCaseDocuments(
          organizationId,
          caseId,
          pageNum,
          pageSize,
          filters
        );

        const documents = response.caseDocuments || [];

        const grouped = documents.reduce((acc, doc) => {
          const category = doc.evidenceCategory || "Uncategorized";
          if (!acc[category]) acc[category] = [];
          acc[category].push(doc);
          return acc;
        }, {});

        setGroupedDocuments(grouped);
      } catch (error) {
        console.error("Error fetching Case Documents:", error);
      }

      setLoading(false);
    };

    fetchDocuments();
  }, [caseId]);



  const handleUpload = (file) => {

  };

  const handleTrigger = async () => {
    setTriggerButton(true);
    const res = await attemptTrigger(organizationId, caseId);
    setTriggerButton(false);
    if (res && res?.status === "Success") {
      navigate(`/cases/${caseId}`);
    }
  };

  return (
    <>
      {loading && (
        <div className="absolute inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="flex flex-col p-4 bg-[#fff]">
            <Loader />

            {" We are processing your documents and generating a response"}
          </div>
        </div>
      )}
      <div className="flex justify-center">
        <div className="flex items-center justify-start gap-6 py-3 px-3 rounded-[4px] bg-[#FBF4EC] border-[#FBF4EC] w-[626px] mt-10">
          <Image src={warningIcon} alt="warningIcon" />
          <div className="flex flex-col">
            <div className="font-inter text-[14px] font-semibold leading-[150%] text-[#D28E3D]">
              {uploadContent.heading}
            </div>
            <div className="font-inter text-sm font-normal leading-[150%] text-[#D28E3D]">
              {uploadContent.para}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-8 ">
        <div className="flex flex-col">
          <Label text={"Upload Primary Complaint"} htmlFor={"complain"} />
          <Uploader
            alreadyUploadedDocs={groupedDocuments["customer_evidence"]}
            countComplain={countComplain}
            setCountComplain={setCountComplain}
            onFilesSelected={handleUpload}
            id={"complain"}
            caseId={caseId}
            organizationId={organizationId}
            tags={"customer_evidence"}
          />
        </div>
      </div>
      <div className="flex justify-center mt-8">
        <div className="flex flex-col">
          <Label text={"Upload Ombudsman Evidence"} htmlFor={"evidence"} />
          <Uploader
            alreadyUploadedDocs={groupedDocuments["ombudsman_evidence"]}
            countComplain={countOmbudsman}
            setCountComplain={setCountOmbudsmaan}
            onFilesSelected={handleUpload}
            id={"ombudsman"}
            caseId={caseId}
            organizationId={organizationId}
            tags={"ombudsman_evidence"}
          />
        </div>
      </div>
      <div className="flex justify-center mt-8">
        <div className="flex flex-col">
          <Label text={"Upload Internal Evidence"} htmlFor={"internal"} />
          <Uploader
            alreadyUploadedDocs={groupedDocuments["internal_evidence"]}
            countComplain={countInternal}
            setCountComplain={setCountInternal}
            onFilesSelected={handleUpload}
            id={"internal"}
            caseId={caseId}
            organizationId={organizationId}
            tags={"internal_evidence"}
          />
        </div>
      </div>
      <div className="flex justify-center mt-10 mb-7">
        {isTriggeringButton ?
          <Loader
            className={"no-class"}
          /> :
          <div
            className="flex justify-end w-[626px] cursor-pointer"
            onClick={handleTrigger}
          >
            <span className="text-[18px] font-semibold">Start</span>
            <Image src={arrowRight} alt="arrowRight" className="font-semibold" />
          </div>
        }
      </div>
    </>
  );
};

export default Caseupload;
