import { Divider } from "../../../components/Divider/Divider";
import Label from "../../../components/Label/Label";
import { useState } from "react";
import Icon from "../../../components/Icon/Icon";
import { getPlaybookCompaints } from "../../../services/coreService";
import Button from "../../../components/Button/Button"
import { CRITERIA_INDENTIFY_SUBCLAIMS, CRITERIA_ASSESS_EVIDENCE, REMEDIAL_ACTION_GUIDANCE, MONETARY_VALUE_GUIDANCE } from "../../../constants/complaintTypesSubkeys";

const Playbook = ({ playbookData, organizationId }) => {

  const [isLabelClicked, setIsLabelClicked] = useState(false);
  const [expandedComplaints, setExpandedComplaints] = useState([]);

  const [playbookComplaintsData, setplaybookComplaintsData] = useState([]);
  const [selectedPlaybook, setSelectedPlaybook] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCreatePlaybook = () => {

    setIsModalOpen(false);
  };

  const fetchPlaybook = async (playbookId) => {

    try {
      const res = await getPlaybookCompaints(playbookId, organizationId);

      if (res?.status === "Success") {
        const { playbookComplaints } = res.data;
        setplaybookComplaintsData(playbookComplaints);
      }
    } catch (error) {
      console.log(error);
    }
  }


  const handlePlaybookClick = (data) => {
    setIsLabelClicked(true);
    setSelectedPlaybook(data);
    fetchPlaybook(data._id);
  };

  const toggleComplaintDetails = (complaintId) => {
    setExpandedComplaints((prev) =>
      prev.includes(complaintId)
        ? prev.filter((id) => id !== complaintId)
        : [...prev, complaintId]
    );
  };

  const renderSection = (label, id, placeholder, maxLength, value) => (
    <div className="mb-6">
      <Label
        className="font-inter text-[16px] font-medium text-[#727272] mb-2"
        htmlFor={id}
        text={label}
      />
      <textarea
        id={id}
        className="mt-2 w-full p-3 border border-[#E4E4E4] rounded-[4px] text-[14px] text-[#000] resize-none"
        placeholder={placeholder}
        maxLength={maxLength}
        value={value}
        rows={6}
      />
    </div>
  );

  const renderComplaintDetails = (complaint) => (
    <div className="p-3 rounded-[4px] border border-[#E4E4E4] text-[#000] text-[14px] leading-[150%] space-y-2">
      <div>
        <span className="font-medium text-[#727272]">{CRITERIA_INDENTIFY_SUBCLAIMS}: </span>
        {complaint.criteriaToIdentifySubClaims}
      </div>
      <div>
        <span className="font-medium text-[#727272]">{CRITERIA_ASSESS_EVIDENCE}: </span>
        {complaint.criteriaToAssessEvidence}
      </div>
      <div>
        <span className="font-medium text-[#727272]">{REMEDIAL_ACTION_GUIDANCE}: </span>
        {complaint.remedialActionsGuidance}
      </div>
      <div>
        <span className="font-medium text-[#727272]">{MONETARY_VALUE_GUIDANCE}: </span>
        {complaint.monetaryValueGuidance}
      </div>
    </div>
  );

  return (
    <div className="w-[100%]">

      <div className="grid grid-cols-[26.27%_calc(100%-26.27%)]">
        {/* Left Sidebar */}
        <div
          className="border-r border-[#E4E4E4] bg-[#FFF]"
          style={{ height: "calc(100dvh - 74px)" }}
        >
          <div className="flex flex-col px-4 py-5">
            <div className="flex items-center justify-between pl-5 pb-2 mb-2">
              <Label
                className="font-inter text-[14px] font-medium leading-[140%] text-[#727272]"
                htmlFor={"Playbook"}
                text={"Playbooks"}
              />
              <Icon
                type="PlusIcon"
                className="cursor-pointer text-[#727272]"
                onClick={() => setIsModalOpen(true)} // Add state for modal
              />
            </div>
            <div>
              {playbookData?.map((playbook) => (
                <div
                  key={playbook._id}
                  className={`flex items-center gap-3 self-stretch p-2 h-9 rounded-[4px] cursor-pointer ${selectedPlaybook?._id === playbook._id ? "bg-[#F2F2F2]" : ""
                    }`}
                  onClick={() => handlePlaybookClick(playbook)}
                >
                  <Label
                    className="font-inter text-[16px] font-medium leading-[150%] cursor-pointer"
                    htmlFor={playbook.name}
                    text={playbook.name}
                  />
                </div>
              ))}
            </div>

          </div>

        </div>

        {/* Right Content */}
        <div>

          {isLabelClicked && (
            <>
              <div className="flex w-[100%-17.22%] h-[72px] py-6 px-8 items-center gap-2.5 text-[#000]">
                <div className="text-[20px]">{selectedPlaybook?.name}</div>
              </div>
              <Divider />


              <div
                style={{ height: "calc(100dvh - 148px)", overflowY: "auto" }}
                className="p-4"
              >
                {/* Apology and Complaint Classifying Criteria */}
                <div className="flex flex-col">
                  {renderSection(
                    "Apology Criteria",
                    "apologyLetterGuidance",
                    "Enter Apology Criteria (Limit: 2000 words)",
                    2000,
                    selectedPlaybook?.apologyLetterGuidance
                  )}
                  {renderSection(
                    "Complaint Classifying Criteria",
                    "complaintClassifyingCriteria",
                    "Enter Complaint Classifying Criteria (Limit: 500 words)",
                    500,
                    selectedPlaybook?.complaintClassifyingCriteria
                  )}
                </div>

                {/* Complaint Types */}

                <div className="mb-10">
                  <Label
                    className="font-inter text-[16px] font-medium text-[#727272] mb-4"
                    htmlFor={"complaintTypes"}
                    text={"Complaint Types"}
                  />
                  <div className="mt-2 flex flex-col gap-3">
                    {playbookComplaintsData?.map((complaint) => (
                      <div key={complaint._id} className="flex flex-col">
                        <div
                          className={`flex items-center justify-between p-3 bg-[#F9F9F9] border border-[#E4E4E4] rounded-[4px] cursor-pointer ${expandedComplaints.includes(complaint._id)
                            ? "bg-[#F2F2F2]"
                            : ""
                            }`}
                          onClick={() => toggleComplaintDetails(complaint._id)}
                        >
                          <span className="font-inter text-[14px] font-medium text-[#000]">
                            {complaint.typeOfComplaint}
                          </span>
                          {expandedComplaints.includes(complaint._id) ? (
                            <Icon type="ArrowUpIcon" className="text-[#727272]" />
                          ) : (
                            <Icon type="ArrowDownIcon" className="text-[#727272]" />
                          )}
                        </div>
                        {expandedComplaints.includes(complaint._id) &&
                          renderComplaintDetails(complaint)}
                      </div>
                    ))}
                  </div>

                </div>

              </div>
            </>
          )}

        </div>



        {/*Create Playbook Modal*/}
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg shadow-lg w-[400px]">
              {/* Header */}
              <div className="px-6 py-4 border-b border-gray-200">
                <h2 className="text-lg font-bold text-black">Create a Playbook</h2>
              </div>

              {/* Body */}
              <div className="px-6 py-4">
                <Label
                  className="text-sm font-medium text-gray-700 mb-2 block"
                  htmlFor="playbookName"
                  text="Name"
                />
                <input
                  id="playbookName"
                  className="w-full border border-gray-300 rounded-md px-3 py-2 text-sm"
                  placeholder="Enter Playbook Name"
                />
              </div>

              {/* Footer */}
              <div className="px-6 py-2 flex justify-between border-t border-gray-200">


                <Button className="bg-[#000000] text-black px-4 py-2" text={"Create Now"} onClick={() => handleCreatePlaybook()} />
                <Button className="bg-[#000000] text-black px-4 py-2" text={"Close"} onClick={() => setIsModalOpen(false)} />

              </div>
            </div>
          </div>
        )}



      </div>
    </div>
  );
};

export default Playbook;
