import RouteIndex from "./allRoutes/indexRoute";

const App = () => {
  return (
    <>
      <RouteIndex />
    </>
  );
};

export default App;
