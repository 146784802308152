import React, { useState } from "react";
import Label from "../../../components/Label/Label";
import Logo from "../../../Images/logo.png";
import InputPassword from "../../../components/InputPassword/InputPassword";
import { bannerText } from "../../../constants/bannerConstants";
import Button from "../../../components/Button/Button";
import BannerComponent from "../../../components/Banner/Banner";
import Loader from "../../../components/Loaders/Loader";
import { useParams, useNavigate } from "react-router-dom";
import { attemptResetPassword } from "../../../services/coreService";
import { SIGNIN_ROUTE } from "../../../constants/routeEnums";

const ResetPasswordPage = () => {
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const { token } = useParams();

    const isPasswordMatch = newPassword && confirmPassword && newPassword === confirmPassword;

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!newPassword || !confirmPassword || newPassword !== confirmPassword) {
            return;
        }

        setLoading(true);


        const requestBody = {
            token,
            newPassword

        };

        const response = await attemptResetPassword(requestBody);

        if (response?.status === "Success") {

            navigate(SIGNIN_ROUTE)

        }
        setLoading(false);

    };

    const handleConfirmPasswordChange = (e) => {
        const value = e.target.value;
        setConfirmPassword(value);

        if (newPassword && value !== newPassword) {
            setError("Passwords do not match");
        } else {
            setError("");
        }
    };

    return (
        <div className="min-h-screen flex">
            <BannerComponent />

            <div className="w-1/2 bg-[#f5f5f5f5] flex flex-col items-center p-8">
                {loading && (
                    <div className="absolute inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" data-testid="loading-indicator" >
                        <Loader />
                    </div>
                )}
                <div className="flex items-center space-x-4 mt-[62px]">
                    <img src={Logo} alt={bannerText.logoAlt} className="h-[24px] w-auto" />
                    <h2 className="text-[24px] font-medium leading-[120%] text-gray-900">
                        {bannerText.appName}
                    </h2>
                </div>

                <div className="mt-6 text-center">
                    <h3
                        className="text-[32px] font-medium leading-[120%]"
                        style={{ color: "var(--Color-Tokens-Content-Dark-Primary, #000)" }}
                    >
                        Reset Password
                    </h3>
                </div>

                <form className="w-full max-w-md mt-6 space-y-4 flex flex-col items-center" onSubmit={handleSubmit}>
                    <div className="w-full">
                        <Label
                            htmlFor="new-password"
                            className="block text-[14px] font-normal font-[400] leading-[150%]"
                            style={{ color: "var(--Color-Tokens-Content-Dark-Primary, #000)" }}
                            text="New Password"
                        />
                        <InputPassword
                            id="new-password"
                            name="newPassword"
                            label="New Password"
                            placeholder="Enter your new password"
                            onChange={(e) => setNewPassword(e.target.value)}
                            value={newPassword}
                        />
                    </div>

                    <div className="w-full">
                        <Label
                            htmlFor="confirm-password"
                            className="block text-[14px] font-normal font-[400] leading-[150%]"
                            style={{ color: "var(--Color-Tokens-Content-Dark-Primary, #000)" }}
                            text="Confirm Password"
                        />
                        <InputPassword
                            id="confirm-password"
                            name="confirmPassword"
                            label="Confirm Password"
                            placeholder="Enter your confirm password"
                            onChange={handleConfirmPasswordChange}
                            value={confirmPassword}
                        />

                        {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
                    </div>

                    <Button
                        text="Reset Password"
                        type="submit"
                        className="flex items-center justify-center gap-[8px] w-full py-[9px] px-[18px] align-self-stretch border border-[#FFFFFF33] bg-[#000] text-white rounded-[4px] shadow-[0px_10px_12px_0px_rgba(255,255,255,0.26)_inset,0px_0px_0px_1px_#000,0px_0px_0px_4px_rgba(0,0,0,0.04)]"
                        style={{
                            pointerEvents: isPasswordMatch ? "auto" : "none",
                        }}
                    />
                </form>
            </div>
        </div>
    );
};

export default ResetPasswordPage;
